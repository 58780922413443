import { omit, isNumber, isEmpty } from 'lodash-es';
import storage from 'local-storage-fallback';
import { APPROVE_MOVE_FRAMES } from 'components/reusables/Hives/actionTypes';
import { SIGN_OUT, SOCKET_CONNECTION_FAILED } from 'components/views/SignIn/actionTypes';
import constants from 'appConstants';
import {
    ON_CALCULATE_LAYOUT,
    ON_UPDATE_BHOME_STATUS,
    ON_UPDATE_FRAME_DATA,
    ON_UPDATE_FRAME,
    ON_UPDATE_SYRUP_LEVEL,
    ON_UPDATE_HONEY_LEVEL,
    ON_REMOVE_SWARM_DATA,
    ON_UPDATE_CONFIG,
    ON_REPORT_HOURLY_SOLAR_DATA,
    ON_UPDATE_GPS_LOCATION,
    ON_GET_REVERTED_ENTRANCES,
    ON_SET_LAYOUT,
    ON_RECEIVE_NEW_ROBOT_STATUS,
    ON_MESSAGE_UPDATE,
} from 'actionTypes';
import onUpdateFrameData from 'utils/onUpdateFrameData';
import { FETCH_CURRENT_BHOME_RECEPTION } from 'components/reusables/StatusIcons/actionTypes';
import {
    CLEAR_BHOMES_VIEW,
    ENABLE_ALL_HIVES,
    FETCH_HIVES,
    TOGGLE_FRAMES_HISTORY,
    SET_FRAME_TO_INSPECT,
    FETCH_UPDATE_BHOME_LAST_VISITED,
    FETCH_FRAMES_LAYOUT,
    SET_CURRENT_BHOME,
    FETCH_BHOME_NOTES,
    CREATE_BHOME_NOTE,
    UPDATE_BHOME_NOTE,
    SET_FRAME_TO_INSPECT_BY_FRAME_ID,
    SET_FRAME_TO_INSPECT_BY_RFID,
    FETCH_INSPECTED_FRAME_IMAGES,
    FETCH_RANCHES,
    FETCH_YARDS,
    FETCH_CREATE_YARD,
    FETCH_DELETE_YARD,
    FETCH_UPDATE_YARD,
    FETCH_YARD_STATS_WIDGET_DATA,
    TOGGLE_HIVE_MANAGMENT_MODAL,
    FETCH_BEEKEEPER_RANCHES,
    FETCH_BHOME_STATS_WIDGET_DATA,
    FETCH_MODIFY_MODE,
    FETCH_MODIFY_BULK_MODE,
    CLEAR_YARD_WIDGET_DATA,
    FETCH_UPDATE_BHOME,
    SET_LOCATION_FILTER,
    SET_ITEM_FILTER,
    TOGGLE_BALANCE_HIVE_MODE,
    SET_SELECTED_FRAMES_FOR_BALANCE,
    TOGGLE_SELECTED_HIVE,
    FETCH_LAYOUT_SNAPSHOT,
    SET_SNAPSHOT_TO_SHOW,
    SET_HIVE_TO_INSPECT,
    CHANGE_FILTERS,
    FETCH_PENDING_MESSAGES,
    FETCH_CANCEL_ACTIONS,
    FETCH_DONE_MESSAGES,
    SET_ZOOM_LEVEL,
    RESET_FILTERS,
} from '../actionTypes';
import { UPDATE_BHOME_LOCATION } from '../../Dashboard/actionTypes';
import { FETCH_PUBLIC_BHOME_DATA, FETCH_PUBLIC_FRAME_DATA } from '../../BhomePublic/actionTypes';
import { UPDATE_ADMIN_COMPANY } from '../../Admin/actionTypes';

const initialState = {
    isLoaded: false,
    bhomes: [],
    ranches: [],
    stations: {},
    markedHives: [],
    selectedHives: [],
    entrances: [],
    revertEntrances: [],
    frameToInspect: {},
    inspectedFrameData: {},
    showFramesHistory: false,
    showBeeMap: false,
    isFetched: false,
    isBeehomeTrendsDataShown: false,
    currentBhome: {},
    frames: [],
    currentBhomeOnline: false,
    yards: [],
    areYardsFetched: false,
    yardWidgetData: null,
    bhomeWidgetData: null,
    mode: {},
    beekeeperRanches: [],
    isHiveManagementModalShown: false,
    locationFilter: null,
    itemFilter: null,
    isBalanceHiveModeShown: false,
    selectedFramesForBalance: {},
    isHistoryLayoutSnapshotShown: false,
    snapshots: [],
    areSnapshotsFetched: false,
    currentLayoutData: {},
    pickedSnapshot: null,
    hiveToInspect: null,
    filters: {
        entity: null,
        entityInstance: null,
    },
    pendingMessages: [],
    doneMessages: [],
    zoomLevel: null,
};

const home = (state = initialState, action) => {
    switch (action.type) {
        case ENABLE_ALL_HIVES:
            return {
                ...state,
                frames: state.frames.layout.map(frame => ({
                    ...frame,
                    status:
                        frame.status === constants.SLOT_STATUS.ERROR
                            ? constants.SLOT_STATUS.ERROR
                            : constants.SLOT_STATUS.IDLE,
                })),
            };
        case FETCH_HIVES.success:
            if (isEmpty(state.currentBhome)) {
                storage.removeItem('bhome');
            }

            return {
                ...state,
                bhomes: action.data.bhomes,
            };
        case FETCH_RANCHES.success:
            return {
                ...state,
                ranches: action.data.ranches,
            };
        case FETCH_BEEKEEPER_RANCHES.success:
            return {
                ...state,
                beekeeperRanches: action.data.ranches,
            };
        case FETCH_YARDS.success:
            return {
                ...state,
                yards: action.data.yards,
                areYardsFetched: true,
            };
        case FETCH_CREATE_YARD.success:
            return {
                ...state,
                yards: [...state.yards, action.data.yard],
                bhomes: state.bhomes.map(bhome => {
                    if (action.data.yard?.bhomes?.some(item => item.id === bhome.id)) {
                        return {
                            ...bhome,
                            yard_id: action.data.yard.id,
                            yard_name: action.data.yard.name,
                        };
                    }
                    return bhome;
                }),
            };
        case FETCH_UPDATE_YARD.success:
            return {
                ...state,
                yards: state.yards.map(yard => {
                    if (yard.id === action.data.yard.id) {
                        return {
                            ...yard,
                            ...action.data.yard,
                        };
                    }
                    return yard;
                }),
                bhomes: state.bhomes.map(bhome => {
                    if (bhome.yard_id === action.data.yard.id) {
                        return {
                            ...bhome,
                            yard_name: action.data.yard.name,
                        };
                    }
                    return bhome;
                }),
            };
        case FETCH_DELETE_YARD.success:
            return {
                ...state,
                yards: state.yards.filter(yard => yard.id !== action.data.yard.id),
                bhomes: state.bhomes.map(bhome => {
                    if (bhome.yard_id === action.data.yard.id) {
                        return {
                            ...bhome,
                            yard_id: null,
                            yard_name: null,
                        };
                    }
                    return bhome;
                }),
            };
        case ON_GET_REVERTED_ENTRANCES:
            if (state.currentBhome.id === Number(action.bhomeId)) {
                return {
                    ...state,
                    revertEntrances: action.revertEntrances,
                };
            }
            return state;
        case ON_RECEIVE_NEW_ROBOT_STATUS:
            if (state.currentBhome.id === Number(action.payload.bhomeId)) {
                return {
                    ...state,
                    currentBhome: {
                        ...state.currentBhome,
                        climate: action.payload.climate,
                    },
                };
            }
            return state;
        case SET_CURRENT_BHOME: {
            const bhome = omit(action.bhome, 'data');

            if (action.bhome?.id) {
                storage.setItem('bhome', JSON.stringify(bhome));
            } else {
                storage.removeItem('bhome');
            }
            return {
                ...state,
                currentBhome: {
                    ...bhome,
                    notes: state.currentBhome?.notes || [],
                },
                frames: action.bhome?.id ? state.frames : [],
                selectedHives: [],
                frameToInspect: {},
                inspectedFrameData: {},
                areSnapshotsFetched: false,
            };
        }
        case FETCH_FRAMES_LAYOUT.success:
        case ON_SET_LAYOUT: {
            if (action.type === ON_SET_LAYOUT && state.isHistoryLayoutSnapshotShown) {
                return { ...state };
            }

            return {
                ...state,
                isFetched: true,
                frames: action.data.frames,
                markedHives: action.data.markedHives,
                stations: action.data.stations,
                isHistoryLayoutSnapshotShown: false,
                currentBhome: !isEmpty(state.currentBhome)
                    ? {
                          ...state.currentBhome,
                          settings: action.data.settings,
                      }
                    : state.currentBhome,
            };
        }
        case FETCH_PUBLIC_BHOME_DATA.success:
            return {
                ...state,
                isFetched: true,
                frames: action.data.frames,
                markedHives: action.data.markedHives,
                stations: action.data.stations,
                currentBhome: action.data.bhome,
                bhomes: [action.data.bhome],
            };
        case FETCH_LAYOUT_SNAPSHOT.success:
            return {
                ...state,
                snapshots: action.data || [],
                areSnapshotsFetched: true,
            };
        case SET_SNAPSHOT_TO_SHOW:
            if (!action?.snapshot?.data) {
                return {
                    ...state,
                    ...state.currentLayoutData,
                    currentLayoutData: {},
                    isHistoryLayoutSnapshotShown: false,
                };
            }

            return {
                ...state,
                currentLayoutData: {
                    frames: state.frames,
                    markedHives: state.markedHives,
                    stations: state.stations,
                    currentBhome: state.currentBhome,
                },
                isHistoryLayoutSnapshotShown: true,
                frames: action.snapshot.data.frames,
                markedHives: action.snapshot.data.markedHives,
                stations: action.snapshot.data.stations,
                currentBhome: !isEmpty(state.currentBhome)
                    ? {
                          ...state.currentBhome,
                          settings: action.snapshot.data.settings,
                      }
                    : state.currentBhome,
            };
        case FETCH_CURRENT_BHOME_RECEPTION.success:
            return {
                ...state,
                currentBhomeOnline: action.data.isOnline,
            };
        case ON_CALCULATE_LAYOUT:
            return {
                ...state,
                frames: action.updatedBhome.data.layout.map(frame => {
                    const previousFrame = state.frames.find(item => item.frameId === frame.frameId);

                    return {
                        ...previousFrame,
                        ...frame,
                    };
                }),
            };
        case ON_UPDATE_BHOME_STATUS:
            return {
                ...state,
                bhomes: state.bhomes.map(bhome => {
                    if (bhome.id === Number(action.updatedBhome.id)) {
                        return {
                            ...bhome,
                            status: action.updatedBhome.status,
                        };
                    }

                    return bhome;
                }),
                currentBhome:
                    state.currentBhome && state.currentBhome.id === Number(action.updatedBhome.id)
                        ? {
                              ...state.currentBhome,
                              status: action.updatedBhome.status,
                          }
                        : state.currentBhome,
            };
        case ON_REPORT_HOURLY_SOLAR_DATA:
            return {
                ...state,
                bhomes: state.bhomes.map(bhome => {
                    if (bhome.id === Number(action.payload.bhomeId)) {
                        return {
                            ...bhome,
                            solar_data: action.payload.solar_data,
                        };
                    }

                    return bhome;
                }),
                currentBhome:
                    state.currentBhome && state.currentBhome.id === Number(action.payload.bhomeId)
                        ? {
                              ...state.currentBhome,
                              solar_data: action.payload.solar_data,
                          }
                        : state.currentBhome,
            };
        case ON_UPDATE_CONFIG:
            return {
                ...state,
                bhomes: state.bhomes.map(bhome => {
                    if (bhome.id === Number(action.bhomeId)) {
                        return {
                            ...bhome,
                            settings: action.settings,
                        };
                    }

                    return bhome;
                }),
                currentBhome:
                    state.currentBhome && state.currentBhome.id === Number(action.bhomeId)
                        ? { ...state.currentBhome, settings: action.settings }
                        : state.currentBhome,
            };
        case FETCH_UPDATE_BHOME_LAST_VISITED.success:
            return {
                ...state,
                bhomes: state.bhomes.map(bhome => {
                    if (bhome.id === action.data.visit.bhome_id) {
                        return {
                            ...bhome,
                            last_visited: action.data.visit.last_visited,
                        };
                    }

                    return bhome;
                }),
            };
        case ON_UPDATE_SYRUP_LEVEL:
        case ON_UPDATE_HONEY_LEVEL:
            return {
                ...state,
                bhomes: state.bhomes.map(bhome => {
                    if (bhome.id === action.updatedBhome.id) {
                        return {
                            ...bhome,
                            sensors: action.updatedBhome.sensors,
                        };
                    }

                    return bhome;
                }),
                currentBhome:
                    state.currentBhome && state.currentBhome.id === Number(action.updatedBhome.id)
                        ? {
                              ...state.currentBhome,
                              sensors: action.updatedBhome.sensors,
                          }
                        : state.currentBhome,
            };
        case ON_UPDATE_GPS_LOCATION:
            return {
                ...state,
                bhomes: state.bhomes.map(bhome => {
                    if (bhome.id === Number(action.bhomeId)) {
                        return {
                            ...bhome,
                            gps: action.gps,
                            yard_id: action.yard_id ?? bhome.yard_id,
                            yard_name: action.yard_name ?? bhome.yard_name,
                        };
                    }

                    return bhome;
                }),
                currentBhome:
                    state.currentBhome && state.currentBhome.id === Number(action.bhomeId)
                        ? {
                              ...state.currentBhome,
                              gps: action.gps,
                              yard_id: action.yard_id ?? state.currentBhome.yard_id,
                              yard_name: action.yard_name ?? state.currentBhome.yard_name,
                          }
                        : state.currentBhome,
            };
        case ON_UPDATE_FRAME_DATA:
            return onUpdateFrameData(action, state);
        case ON_REMOVE_SWARM_DATA:
            return {
                ...state,
                frames: state.frames.map(item => {
                    const updatedFrame = action.payload.find(frame => frame.id === item.frameId);
                    if (updatedFrame) {
                        return {
                            ...item,
                            sideA: updatedFrame.side_a,
                            sideB: updatedFrame.side_b,
                        };
                    }
                    return item;
                }),
            };
        case ON_UPDATE_FRAME:
            const { slotIndex, payload } = action;

            const frames = state.frames.map((item, index) => {
                if (isNumber(slotIndex) && slotIndex === index) {
                    // eslint-disable-next-line
                    const { bhome_id, ...rest } = payload;

                    return {
                        ...item,
                        ...rest,
                    };
                }

                if (item.frameId === payload.id) {
                    return {
                        ...item,
                        status: payload.status,
                        type: payload.type,
                        rfid: payload.rfid,
                    };
                }
                return item;
            });

            return {
                ...state,
                frames,
            };
        case APPROVE_MOVE_FRAMES:
            return {
                ...state,
                frames: action.payload.frames,
            };
        case TOGGLE_FRAMES_HISTORY:
            return {
                ...state,
                showFramesHistory: !state.showFramesHistory,
            };
        case SIGN_OUT:
        case SOCKET_CONNECTION_FAILED:
        case CLEAR_BHOMES_VIEW:
            return {
                ...state,
                ...initialState,
            };
        case FETCH_BHOME_NOTES.success:
            return {
                ...state,
                currentBhome: {
                    ...state.currentBhome,
                    notes: action.data,
                },
            };
        case CREATE_BHOME_NOTE.success:
            return {
                ...state,
                currentBhome: {
                    ...state.currentBhome,
                    notes: [action.data, ...state.currentBhome.notes],
                },
            };
        case UPDATE_BHOME_NOTE.success:
            return {
                ...state,
                currentBhome: {
                    ...state.currentBhome,
                    notes: state.currentBhome.notes.map(note =>
                        note.id === action.data.id
                            ? {
                                  ...note,
                                  ...action.data,
                              }
                            : note
                    ),
                },
            };
        case SET_FRAME_TO_INSPECT:
            return {
                ...state,
                frameToInspect: action.frame,
            };
        case SET_HIVE_TO_INSPECT:
            return {
                ...state,
                hiveToInspect: action.hiveId,
            };
        case SET_FRAME_TO_INSPECT_BY_FRAME_ID:
            return {
                ...state,
                frameToInspect: state.frames.find(frame => frame.frameId === action.frameId) || {},
            };
        case SET_FRAME_TO_INSPECT_BY_RFID:
            return {
                ...state,
                frameToInspect: state.frames.find(frame => frame.rfid === action.rfid) || {},
            };
        case FETCH_INSPECTED_FRAME_IMAGES.success:
        case FETCH_PUBLIC_FRAME_DATA.success:
            return {
                ...state,
                inspectedFrameData: action.data,
            };
        case UPDATE_BHOME_LOCATION.success: {
            const { locationId, id, ...rest } = action.data;
            const bhomeData = {
                location_id: locationId,
                ...rest,
            };
            const bhomes = state.bhomes.map(bhome =>
                bhome.id === +id
                    ? {
                          ...bhome,
                          ...bhomeData,
                      }
                    : bhome
            );
            return {
                ...state,
                currentBhome:
                    state.currentBhome.id === id ? { ...state.currentBhome, ...bhomeData } : { ...state.currentBhome },
                bhomes,
            };
        }
        case FETCH_YARD_STATS_WIDGET_DATA.success:
            return {
                ...state,
                yardWidgetData: action.data.stats,
            };
        case CLEAR_YARD_WIDGET_DATA: {
            return {
                ...state,
                yardWidgetData: null,
            };
        }
        case FETCH_BHOME_STATS_WIDGET_DATA.success:
            return {
                ...state,
                bhomeWidgetData: action.data.stats,
            };
        case TOGGLE_HIVE_MANAGMENT_MODAL:
            return {
                ...state,
                isHiveManagementModalShown: !state.isHiveManagementModalShown,
            };
        case TOGGLE_BALANCE_HIVE_MODE:
            return {
                ...state,
                selectedFramesForBalance: {},
                isBalanceHiveModeShown: action.isBalanceHiveModeShown,
            };
        case FETCH_MODIFY_MODE.success:
            return {
                ...state,
                mode: action.data.mode,
                pendingMessages: [],
            };
        case FETCH_MODIFY_BULK_MODE.success:
            return {
                ...state,
                mode: action.data.mode,
            };
        case FETCH_UPDATE_BHOME.success:
            return {
                ...state,
                currentBhome: action.data.bhome,
            };
        case SET_LOCATION_FILTER:
            return { ...state, locationFilter: action.locationFilter };
        case SET_ITEM_FILTER:
            return { ...state, itemFilter: action.itemFilter };
        case SET_SELECTED_FRAMES_FOR_BALANCE:
            return {
                ...state,
                selectedFramesForBalance: action.selectedFramesForBalance,
            };
        case TOGGLE_SELECTED_HIVE: {
            let selectedHives = [];
            if (action.hiveId) {
                selectedHives = !state.selectedHives.includes(action.hiveId)
                    ? [...state.selectedHives, action.hiveId]
                    : state.selectedHives.filter(hiveId => hiveId !== action.hiveId);
            }
            return {
                ...state,
                selectedHives,
            };
        }
        case CHANGE_FILTERS:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    ...action.filters,
                },
            };
        case RESET_FILTERS:
            return {
                ...state,
                filters: {
                    ...initialState.filters,
                },
            };
        case FETCH_PENDING_MESSAGES.success:
            return {
                ...state,
                pendingMessages: action.data,
            };
        case FETCH_CANCEL_ACTIONS.success:
            return {
                ...state,
                pendingMessages: state.pendingMessages.filter(item => !action.data.includes(item.id)),
            };
        case FETCH_DONE_MESSAGES.success:
            return {
                ...state,
                doneMessages: action.data,
            };
        case SET_ZOOM_LEVEL:
            return { ...state, zoomLevel: action.zoomLevel };
        case ON_MESSAGE_UPDATE: {
            const { message } = action.payload;
            const updatedPendingMessages = state.pendingMessages.filter(
                activity =>
                    !(
                        activity.command === message.command &&
                        activity.user_id === message.user_id &&
                        activity.sent_at === message.sent_at
                    )
            );

            if (
                constants.MESSAGE_STATUS.SUCCESS ||
                constants.MESSAGE_STATUS.FAILED ||
                constants.MESSAGE_STATUS.PROCESSING ||
                constants.MESSAGE_STATUS.SENT ||
                constants.MESSAGE_STATUS.ACKNOWLEDGED ||
                constants.MESSAGE_STATUS.CANCELED
            ) {
                const updatedMessage = state.pendingMessages.find(activity => activity.id === message.id);
                const updatedDoneMessages = [{ ...updatedMessage, status: message.status }, ...state.doneMessages];

                return {
                    ...state,
                    pendingMessages: updatedPendingMessages,
                    doneMessages: updatedDoneMessages,
                };
            }

            return {
                ...state,
            };
        }
        case UPDATE_ADMIN_COMPANY.success:
            return {
                ...state,
                bhomes: [],
            };
        default:
            return state;
    }
};

export default home;
