import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars-2';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash-es';
import { loading } from '@beewise/react-utils';
import Hives from 'components/reusables/Hives';
import { ContentLegend, FrameLegend, ExtraLegendData } from 'components/reusables/Hives/components/Legend';
import { fetchActivities } from 'components/reusables/RightPanel/actions/activity.actions';
import { toggleInitialRender, toggleRightPanel } from 'actions';
import RightPanel from 'components/reusables/RightPanel';
import LeftPanel from 'components/reusables/LeftPanel';
import { Map } from 'components/reusables/Map';
import { getIsLimitedViewCompany, getIsMapShown, getIsRightPanelShown, getUser } from 'selectors';
import {
    fetchCompanyLocations,
    fetchAllBhomesOnline,
    fetchDashboardGridData,
} from 'components/views/Dashboard/actions';
import BhomeActions from 'components/reusables/RightPanel/components/BhomeActions';
import HiveManagementModal from 'components/reusables/HiveManagementModal';
import { fetchYards, toggleHiveManagementModal } from 'components/views/BeeHome/actions';
import YardModal from 'components/reusables/Map/components/YardModal';
import Widgets from './components/Widgets';
import { FETCH_CURRENT_BHOME, FETCH_FRAMES_LAYOUT, FETCH_HIVES } from './actionTypes';
import { getCurrentBhome, getIsCurrentBhomeOnline, getIsHiveManagementModalShown } from './selectors';
import {
    fetchUpdateLastVisited,
    fetchCurrentBhome,
    fetchHives,
    fetchFrames,
    fetchRanches,
    fetchClimateInfo,
} from './actions';

import './Beehome.scss';
import { fetchWorkspaceData } from '../Workspace/actions';

const BeeHome = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();
    const [map, setMap] = useState();
    const [maps, setMaps] = useState();

    const bhomeId = Number(params.id);
    const currentBhome = useSelector(getCurrentBhome, shallowEqual);
    const isMapShown = useSelector(getIsMapShown);
    const isHiveManagementModalShown = useSelector(getIsHiveManagementModalShown);
    const isRightPanelShown = useSelector(getIsRightPanelShown);
    const isBhomeOnline = useSelector(getIsCurrentBhomeOnline);
    const isSettingsReady = !isEmpty(currentBhome?.settings);
    const isLimitedView = useSelector(getIsLimitedViewCompany);
    const user = useSelector(getUser, shallowEqual);

    const [yardAndCoordsInfo, setYardAndCoordsInfo] = useState({});
    const [locationType, setLocationType] = useState(null);

    const updateYardAndCoordsInfo = props => {
        setYardAndCoordsInfo(prevProps => ({
            ...prevProps,
            ...props,
        }));
    };

    const closeYardModal = newYard => {
        if (map && newYard) {
            const newCenter = { lat: newYard?.lat, lng: newYard?.lng };
            map.setCenter(newCenter);
        }
        updateYardAndCoordsInfo({ editYard: null, createCoords: null });
        setLocationType(null);
    };

    useEffect(() => {
        dispatch(
            fetchHives(data => {
                if (data.bhomes?.length && !bhomeId) {
                    const bhomeToNavigate = isLimitedView ? data.bhomes.find(item => item.has_scan) : data.bhomes[0];
                    navigate(`/beehome/${bhomeToNavigate.id}`);
                }
            })
        );
        dispatch(fetchCompanyLocations());
        dispatch(fetchAllBhomesOnline());
        dispatch(fetchRanches());
        dispatch(fetchYards());
        dispatch(toggleRightPanel(true));
        dispatch(fetchWorkspaceData());
        dispatch(fetchDashboardGridData({ offset: 0, limit: 0, type: '' }));
        // eslint-disable-next-line
    }, [dispatch]);

    useEffect(() => {
        // fetch new climate status in case it is older than 60 minutes
        if (
            isBhomeOnline &&
            currentBhome?.id &&
            (!currentBhome?.climate?.time ||
                (currentBhome?.climate && currentBhome?.climate?.time < Date.now() - 1000 * 60 * 60))
        ) {
            dispatch(fetchClimateInfo(currentBhome?.id));
        }
        // eslint-disable-next-line
    }, [dispatch, currentBhome?.climate, currentBhome?.id]);

    useEffect(() => {
        if (!bhomeId) {
            return;
        }

        dispatch(fetchFrames(bhomeId));

        if (currentBhome.id !== bhomeId) {
            dispatch(fetchCurrentBhome(bhomeId));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, bhomeId]);

    useEffect(() => {
        if (!currentBhome.id) {
            return;
        }

        dispatch(fetchActivities(currentBhome.id));

        if (!currentBhome.last_visited || !dayjs(currentBhome.last_visited).isToday()) {
            dispatch(fetchUpdateLastVisited(currentBhome.id));
        }
    }, [currentBhome.id, currentBhome.last_visited, dispatch]);

    useEffect(() => {
        dispatch(toggleInitialRender(true));
    }, [dispatch, user.companyId]);

    const handleHiveManagementModalClose = useCallback(() => dispatch(toggleHiveManagementModal()), [dispatch]);

    const handleOnMapReady = useCallback((map, maps) => {
        setMap(map);
        setMaps(maps);
    }, []);

    const MainComponentMemoized = useMemo(
        () =>
            isMapShown ? (
                <Map
                    onMapReady={handleOnMapReady}
                    maps={maps}
                    map={map}
                    updateYardAndCoordsInfo={updateYardAndCoordsInfo}
                    newLocationType={locationType}
                    setNewLocationType={setLocationType}
                />
            ) : (
                <Scrollbars>
                    <div className="beehome">
                        <div className="beehome-content beehome-content-v4">
                            {!isLimitedView && <Widgets currentBhomeId={currentBhome?.id} />}
                            <Hives />
                            {isSettingsReady && (
                                <div className="legend-wrapper legend-wrapper-v4">
                                    <FrameLegend />
                                    <ContentLegend />
                                    <ExtraLegendData />
                                </div>
                            )}
                        </div>
                    </div>
                </Scrollbars>
            ),
        [isMapShown, handleOnMapReady, maps, map, locationType, currentBhome?.id, isSettingsReady, isLimitedView]
    );

    const RightPanelMemoized = useMemo(() => {
        if (!isMapShown || isRightPanelShown) {
            return <RightPanel location={currentBhome.location} isMapShown={isMapShown} />;
        }
        return null;
    }, [currentBhome.location, isMapShown, isRightPanelShown]);

    return (
        <main className="main">
            <BhomeActions />
            <div className="main-content">
                <LeftPanel
                    currentBhome={currentBhome}
                    map={map}
                    maps={maps}
                    updateYardAndCoordsInfo={updateYardAndCoordsInfo}
                    setNewLocationType={setLocationType}
                />
                {MainComponentMemoized}
                {RightPanelMemoized}
            </div>
            <HiveManagementModal
                isOpen={isHiveManagementModalShown}
                title="Hive management"
                onClose={handleHiveManagementModalClose}
            />
            {!isEmpty(yardAndCoordsInfo.createCoords) && (yardAndCoordsInfo.editYard || locationType) && (
                <YardModal {...yardAndCoordsInfo} onClose={closeYardModal} />
            )}
        </main>
    );
};

export default loading([FETCH_CURRENT_BHOME.default, FETCH_FRAMES_LAYOUT.default, FETCH_HIVES.default])(BeeHome);
