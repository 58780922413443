import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import Tooltip from '@beewise/tooltip';
import { faXmark } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Icon from 'components/reusables/Icon';
import './Badge.scss';
import { noop } from 'lodash-es';

export const BADGE_TYPE = {
    DEFAULT: 'default',
    DEFAULT_LIGHT: 'default-light',
    SUCCESS: 'success',
    WARN: 'warn',
    ERROR: 'error',
    INFO: 'info',
};

export const BADGE_FORM = {
    CIRCLE: 'circle',
    SQUARE: 'square',
};

export const VARIANT = {
    OUTLINED: 'outlined',
    FILLED: 'filled',
};

export const SIZE = {
    SMALL: 'small',
    MEDIUM: 'medium',
    LARGE: 'large',
};

const Badge = ({
    tooltipContent,
    children,
    icon,
    tooltipPosition = 'bottom center',
    type = BADGE_TYPE.INFO,
    form = BADGE_FORM.CIRCLE,
    variant = VARIANT.FILLED,
    size = SIZE.MEDIUM,
    className,
    onCancel,
    onClick = noop,
    tooltipClassName,
    ...props
}) => {
    const Wrapper = tooltipContent ? Tooltip : React.Fragment;
    const wrapperProps = tooltipContent
        ? { content: tooltipContent, position: tooltipPosition, className: tooltipClassName }
        : {};

    return (
        <Wrapper {...wrapperProps}>
            <span
                role="presentation"
                className={cn('badge', variant, size, className, { [`badge-${type}`]: type, [`badge-${form}`]: form })}
                onClick={onClick}
                {...props}
            >
                {!!icon && <Icon icon={icon} />}
                {children}
                {!!onCancel && <FontAwesomeIcon icon={faXmark} className="icon-cancel" onClick={onCancel} />}
            </span>
        </Wrapper>
    );
};

Badge.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    icon: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]),
    tooltipContent: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    type: PropTypes.oneOf(Object.values(BADGE_TYPE)),
    form: PropTypes.oneOf(Object.values(BADGE_FORM)),
    variant: PropTypes.oneOf(Object.values(VARIANT)),
    size: PropTypes.oneOf(Object.values(SIZE)),
    onClick: PropTypes.func,
    onCancel: PropTypes.func,
    tooltipPosition: PropTypes.string,
    tooltipClassName: PropTypes.string,
};

export default Badge;
