import React, { useRef } from 'react';
import cn from 'classnames';
import Error from './Error';
import { SIZES, TYPES, POSITIONS, getDisplayValue } from './utils';
import useTextField from './useTextField';
import Input from './Input';
import Label from './Label';
import props from './props';

import './TextField.scss';

const getIcon = (icon, position) =>
  icon && <div className={cn('text-field-icon', position)}>{icon}</div>;

const TextField = React.forwardRef(
  (
    {
      error,
      name,
      label,
      required,
      value,
      onFocus,
      onBlur,
      onChange,
      multiline,
      size = SIZES.NORMAL,
      type = TYPES.TEXT,
      className,
      placeholder,
      iconLeft,
      iconRight,
      info,
      ...rest
    },
    ref
  ) => {
    const inputRef = useRef(null);

    const { handleOnFocus, handleOnBlur, handleOnChange, active, initialActiveState } =
      useTextField({
        value,
        inputRef,
        onFocus,
        onBlur,
        onChange,
        type
      });

    const displayValue = getDisplayValue({ type, value });

    const setRef = (r) => {
      if (ref) {
        ref.current = r;
      }
      inputRef.current = r;
    };

    return (
      <fieldset className={cn('text-field-root', className)}>
        <div
          className={cn('text-field-container', {
            'text-field--error': error
          })}
        >
          {getIcon(iconLeft, POSITIONS.LEFT)}
          <Input
            ref={setRef}
            name={name}
            id={name}
            type={type}
            value={displayValue}
            placeholder={placeholder}
            multiline={multiline}
            size={size}
            active={active}
            error={error}
            onFocus={handleOnFocus}
            onBlur={handleOnBlur}
            onChange={handleOnChange}
            {...rest}
          />
          {getIcon(iconRight, POSITIONS.RIGHT)}
        </div>
        <Label
          label={label}
          name={name}
          // keep label on the top if placeholder is present
          active={active || placeholder || initialActiveState}
          error={error}
          multiline={multiline}
          size={size}
          required={required}
          isWithLeftIcon={!!iconLeft}
          isWithRightIcon={!!iconRight}
        />
        <Error info={info} error={error} />
      </fieldset>
    );
  }
);

TextField.propTypes = props;

export default TextField;
