import dayjs from 'dayjs';
import constants from 'appConstants';

const EVENT_STATUSES = {
    SUCCESSFULLY_FILLED: 'SUCCESSFULLY_FILLED',
    ALREADY_FULL: 'ALREADY_FULL',
};

export const HIVE_CATEGORY_COLORS = {
    [constants.HIVE_ASSESSMENT.STRONG]: 'green',
    [constants.HIVE_ASSESSMENT.MEDIUM]: 'yellow',
    [constants.HIVE_ASSESSMENT.WEAK]: 'red',
    [constants.HIVE_ASSESSMENT.DATA_UNAVAILABLE]: 'grey',
};

export const CATEGORY_DATA = [
    { color: '#F25E5E', label: 'Weak', range: '0-4' },
    { color: '#FFA03A', label: 'Medium', range: '4-8' },
    { color: '#59C16C', label: 'Strong', range: '8+' },
    { color: '#BDBDBD', label: 'No data' },
];

export const CATEGORY_DATA_CHART = [
    { color: '#59C16C', label: 'Strong', nameColor: 'green' },
    { color: '#FFA03A', label: 'Medium', nameColor: 'yellow' },
    { color: '#F25E5E', label: 'Weak', nameColor: 'red' },
    { color: '#BDBDBD', label: 'No data', nameColor: 'grey' },
];

export const DEFAULT_HIVE_CONFIGURATION = 8;

export const SUCCESSFULLY_FILLED_STATUS = 'SUCCESSFULLY_FILLED';
export const EXPANDED_ENTITY_HEIGHT = 148;
export const SUMMARY_BAR_HEIGHT = 115;
export const APP_HEADER_HEIGHT = 54;
export const RED_SYRUP_LEVEL = 5;
export const SHIFT = 'Shift';
export const GRAPHS_LEVELS = {
    brood: {
        medium: 8,
        low: 4,
    },
    empty: {
        medium: 5,
        low: 2,
    },
    honey: {
        medium: 5,
        low: 2,
    },
    population: {
        medium: 10,
        low: 4,
    },
};

export const actionsValues = {
    TRANSPORT_ACTION: constants.MODE.TRANSPORTATION,
    VISIT_ACTION: constants.MODE.VISIT,
    FEED_ACTION: 'feed',
};

export const getRowSize = () => EXPANDED_ENTITY_HEIGHT;

export const TRENDS = {
    POSITIVE: 'positive',
    NEGATIVE: 'negative',
};

export const SORT_WORKSPACE_OPTIONS = [
    { value: 'location-north-to-south', label: 'Location - North to South' },
    { value: 'location-south-to-north', label: 'Location - South to North' },
];

export const ACTION_START = 'start';
export const ACTION_END = 'end';

export const NO_YARD_ID = 'Unknown Yard';
export const NO_RANCH_ID = 'Unknown Ranch';
const SYRUP_CAPACITY = 20;

export const WEEK_LABELS = {
    THIS_WEEK: 'This week:',
    LAST_WEEK: 'Last week:',
    LAST_MONTH: 'Last month:',
};

const DATE_FORMAT_FOR_TOOLTIP = 'D MMM';

const processAverages = (bhome, acc) => {
    const updateAcc = (value, levels, accArray) => {
        if (value > levels.medium) {
            accArray[2] += 1;
        } else if (value > levels.low) {
            accArray[1] += 1;
        } else {
            accArray[0] += 1;
        }
    };
    updateAcc(bhome.averageBroodWithin2Weeks, GRAPHS_LEVELS.brood, acc.averageBrood);
    updateAcc(bhome.averageEmptyWithin2Weeks, GRAPHS_LEVELS.empty, acc.averageEmpty);
    updateAcc(bhome.averageHoneyWithin2Weeks, GRAPHS_LEVELS.honey, acc.averageHoney);
    updateAcc(bhome.averagePopulationWithin2Weeks, GRAPHS_LEVELS.population, acc.averagePopulation);
};

export const isInCurrentWeekFeed = timestamp => {
    if (!timestamp) return false;
    const now = dayjs();
    const feedTime = dayjs(timestamp);
    return feedTime.isAfter(now.startOf('week')) && feedTime.isBefore(now.endOf('week'));
};

const getDefaultBaseAccumulator = () => ({
    totalHives: 0,
    totalBrood: 0,
    totalEmpty: 0,
    totalHoney: 0,
    totalPopulation: 0,
});

const getDefaultAccumulator = () => ({
    lastFeedTime: null,
    syrupRequired: 0,
    syrupLow: 0,
    totalSyrup: 0,
    larvaePresence: 0,
    ...getDefaultBaseAccumulator(),
    totalThermalAssessmentDeadHives: 0,
    totalAmountOfEmptyBroodHives: 0,
    averagePopulation: [0, 0, 0],
    averageBrood: [0, 0, 0],
    averageEmpty: [0, 0, 0],
    averageHoney: [0, 0, 0],
    coordsState: null,
    totalSyrupVolume: 0,
    failedFeedingsCount: 0,
    partiallyFeedingsCount: 0,
});

const SYRUP_LEVELS = {
    LOW: 20,
    CAPACITY: SYRUP_CAPACITY,
};

const processSyrupData = (bhome, acc) => {
    const syrupLevel = bhome.syrupLevel ?? 0;
    acc.syrupRequired += SYRUP_LEVELS.CAPACITY * ((100 - syrupLevel) / 100);
    acc.syrupLow += syrupLevel < SYRUP_LEVELS.LOW ? 1 : 0;
    acc.totalSyrup += Number.isNaN(syrupLevel) ? 0 : Number(syrupLevel);
    acc.totalSyrupVolume += SYRUP_LEVELS.CAPACITY;
};

const processHiveData = (bhome, acc) => {
    acc.totalBrood += bhome.averageBroodWithin2Weeks;
    acc.totalEmpty += bhome.averageEmptyWithin2Weeks;
    acc.totalHoney += bhome.averageHoneyWithin2Weeks;
    acc.totalPopulation += bhome.averagePopulationWithin2Weeks;
    acc.totalHives += bhome.markedHives?.length ?? 0;
};

const processStatusData = (bhome, acc) => {
    if (bhome.lastFeedTime && bhome.lastFeedTime > acc.lastFeedTime) {
        acc.lastFeedTime = bhome.lastFeedTime;
    }
    if (bhome.totalAmountOfBroodHives) {
        acc.larvaePresence += bhome.totalAmountOfBroodHives;
    }
    if (bhome.totalAmountOfEmptyBroodHives) {
        acc.totalAmountOfEmptyBroodHives += bhome.totalAmountOfEmptyBroodHives;
    }
    if (bhome.totalThermalAssessmentDeadHives) {
        acc.totalThermalAssessmentDeadHives += bhome.totalThermalAssessmentDeadHives;
    }
    if (bhome.failedFeedingsCount) {
        acc.failedFeedingsCount += bhome.failedFeedingsCount;
    }
    if (bhome.partiallyFeedingsCount) {
        acc.partiallyFeedingsCount += bhome.partiallyFeedingsCount;
    }
    acc.coordsState = bhome.coordsState;
};

const aggregateBhomeData = (data, isHistorical = false) => {
    if (!Array.isArray(data) || !data.length) {
        return isHistorical ? getDefaultBaseAccumulator() : getDefaultAccumulator();
    }

    const aggregatedData = data.reduce(
        (acc, bhome) => {
            if (isHistorical) {
                processHiveData(bhome, acc);
                return acc;
            }

            processStatusData(bhome, acc);
            processHiveData(bhome, acc);
            processSyrupData(bhome, acc);
            processAverages(bhome, acc);
            return acc;
        },
        isHistorical ? getDefaultBaseAccumulator() : getDefaultAccumulator()
    );

    return aggregatedData;
};

const ENTITY_FIELDS = {
    RANCH: {
        name: 'ranchName',
        regionId: 'regionRanchId',
        regionName: 'regionRanchName',
    },
    YARD: {
        name: 'yardName',
        regionId: 'regionYardId',
        regionName: 'regionYardName',
    },
};

const getEntityFields = isRanch => ENTITY_FIELDS[isRanch ? 'RANCH' : 'YARD'];

export const mapData = (todaysGroupedData, previousPeriodGroupedDate, isRanch) => (acc, key) => {
    if (!key || key === 'null') {
        return acc;
    }
    const recentCreatedAt = Math.max(...todaysGroupedData[key].map(({ createdAt }) => createdAt));
    const recentData = todaysGroupedData[key].filter(({ createdAt }) => recentCreatedAt === createdAt);
    const bhomesAggregatedData = aggregateBhomeData(recentData);
    const previousPeriodBhomesAggregatedData = aggregateBhomeData(previousPeriodGroupedDate[key], true);
    const firstItem = todaysGroupedData[key][0];

    const fields = getEntityFields(isRanch);

    if (!key || key === 'null') {
        return acc;
    }

    acc.push({
        id: key,
        name: firstItem[fields.name],
        regionId: firstItem[fields.regionId],
        regionName: firstItem[fields.regionName],
        previousPeriod: { ...previousPeriodBhomesAggregatedData, data: previousPeriodGroupedDate[key] },
        ...bhomesAggregatedData,
        data: todaysGroupedData[key],
        isRanch,
    });
    return acc;
};

export const sortYards = (a, b, sort) => {
    // TODO what has to be done here?
    if (a.id === NO_YARD_ID || a.id === NO_RANCH_ID) {
        return 1;
    }
    if (b.id === NO_YARD_ID || b.id === NO_RANCH_ID) {
        return -1;
    }
    if (sort === SORT_WORKSPACE_OPTIONS[0].value) {
        return b.lat - a.lat;
    } else if (sort === SORT_WORKSPACE_OPTIONS[1].value) {
        return a.lat - b.lat;
    }
    return a.data?.length > b.data?.length ? -1 : 1;
};

export const getLatestVisitEndTime = data => {
    const visitActions = data?.filter(item => item.action === constants.MODE.VISIT);

    if (!visitActions?.length) {
        return;
    }

    const latestVisit = visitActions.reduce((latest, current) => {
        const currentEndTime = new Date(current.end_time);
        return currentEndTime > new Date(latest.end_time) ? current : latest;
    });

    return latestVisit.end_time;
};

const formatDate = date => {
    if (!date) return 'N/A';
    return dayjs(date).format(DATE_FORMAT_FOR_TOOLTIP);
};

const calculateAverage = (value, count, decimalPlaces) => {
    if (!value) return null;
    return Number(value / (count ?? 1)).toFixed(decimalPlaces);
};

const calculateMetrics = (metrics, rawData) => ({
    population: calculateAverage(metrics.totalPopulation, rawData.length),
    brood: calculateAverage(metrics.totalBrood, rawData.length),
    empty: calculateAverage(metrics.totalEmpty, rawData.length),
    honey: calculateAverage(metrics.totalHoney, rawData.length),
});

export const getEntityData = entity => {
    const larvaePercentage = entity.larvaePresence
        ? Number(((entity.larvaePresence || 0) / (entity.totalHives || 1)) * 100).toFixed(0)
        : '-';
    const lastVisitTime = getLatestVisitEndTime(entity.workspaceActions);
    const lastVisitTimeFormatted = formatDate(lastVisitTime);
    const lastFeedTime = formatDate(entity.lastFeedTime);
    const averageSyrup = entity.totalSyrup ? ((entity.totalSyrup || 0) / (entity.data?.length || 1)).toFixed(0) : '-';
    const metrics = calculateMetrics(entity, entity.data);
    const previousPeriodMetrics = calculateMetrics(entity.previousPeriod, entity.previousPeriod.data);
    return {
        ...metrics,
        larvaePercentage,
        lastVisitTimeFormatted,
        lastFeedTime,
        averageSyrup,
        previousPeriod: previousPeriodMetrics,
    };
};

export const getYardBhomeIds = (yards, selectedIds, yardId) => {
    if (yardId) {
        const yard = yards.find(yard => yard.id === yardId);

        return yard ? yard.bhomes.map(bhome => bhome.id) : [];
    }

    const selectedYards = yards.filter(yard => selectedIds.includes(yard.id));

    return selectedYards.flatMap(yard => yard.bhomes.map(bhome => bhome.id));
};

export const getRanchBhomeIds = (workspaceData, selectedIds, ranchId) => {
    if (ranchId) {
        const ranch = workspaceData.find(ranch => ranch.id === ranchId);
        return ranch ? ranch.data.map(item => item.id) : [];
    }

    const selectedRanches = workspaceData.filter(ranch => selectedIds.includes(ranch.id));
    return selectedRanches.flatMap(ranch => ranch.data.map(item => item.id));
};

export const getRowIdsToUpdate = (selectedIds, workspaceActions, modes) =>
    selectedIds.filter(
        id =>
            !workspaceActions.some(
                workspaceAction =>
                    (workspaceAction.yard_id === id || workspaceAction.ranch_id === id) &&
                    !workspaceAction.end_time &&
                    modes.includes(workspaceAction.action)
            )
    );

export const findWorkspaceActionIds = (workspaceActions, rowId) =>
    workspaceActions
        .filter(action => (action.yard_id === rowId || action.ranch_id === rowId) && !action.end_time)
        .sort((a, b) => new Date(a.start_time) - new Date(b.start_time))
        .map(action => action.id);

export const smoothScroll = (start, end, duration, listRef) => {
    const diff = end - start;
    let startTime;

    const scroll = timestamp => {
        if (!startTime) startTime = timestamp;

        const elapsed = timestamp - startTime;
        const progress = Math.min(elapsed / duration, 1);

        listRef.current.scrollTo(start + diff * progress);

        if (progress < 1) {
            requestAnimationFrame(scroll);
        }
    };

    requestAnimationFrame(scroll);
};

export const getSelectedYardsWithFeedMode = ({ selectedIds, workspaceActions }) =>
    selectedIds.every(id =>
        workspaceActions?.find(
            yardAction =>
                yardAction.yard_id === id && !yardAction?.end_time && yardAction.action === actionsValues.FEED_ACTION
        )
    );

export const getSelectedActiveModes = ({ selectedIds, workspaceActions }) =>
    selectedIds.reduce((acc, id) => {
        const workspaceActiveAction = workspaceActions?.find(
            workspaceAction => workspaceAction.yard_id === id && !workspaceAction?.end_time
        );

        if (workspaceActiveAction) {
            acc.push(workspaceActiveAction?.action);
        }
        return acc;
    }, []);

export const calculateWeightDifference = event => {
    const initialWeight = event.payload.initial_frame_weight;
    const afterFillWeight = event.payload.after_fill_frame_weight;
    return afterFillWeight - initialWeight;
};
export const totalWeightDifference = (events = []) => {
    if (!Array.isArray(events)) {
        return '0.0';
    }

    return events
        .reduce((acc, event) => {
            if (event.status !== EVENT_STATUSES.SUCCESSFULLY_FILLED) {
                return acc;
            }
            const weightDiff = calculateWeightDifference(event);
            return acc + (weightDiff * constants.RATIO_GAL_TO_GRAMM || 0);
        }, 0)
        .toFixed(1);
};

export const calculateSyrupLevel = currentBhome => {
    const syrupLevel = Number(currentBhome?.sensors?.syrupLevel) || 0;
    return ((syrupLevel / 100) * constants.SYRUP_GALLONS).toFixed(1);
};
