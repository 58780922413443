import { shallowEqual, useSelector } from 'react-redux';
import { isBoolean, get, merge } from 'lodash-es';
import constants from 'appConstants';
import { getCurrentBhome } from 'components/views/BeeHome/selectors';
import { getUser } from 'selectors';

export const useFeatureFlag = featureName => {
    const { role } = useSelector(getUser, shallowEqual);
    const bhome = useSelector(getCurrentBhome, shallowEqual);

    // disallow super_viewer to send any action
    if (role === constants.ROLES.SUPER_VIEWER) {
        return false;
    }

    if (bhome) {
        const featureFlags = merge(
            get(bhome, 'settings.ui_settings.featureFlags', {}),
            get(bhome, 'settings.defaults.ui_settings.featureFlags', {}),
            get(bhome, 'settings.machine_specific.ui_settings.featureFlags', {}),
            get(bhome, 'config.featureFlags', {})
        );

        if (!featureName) {
            return {
                ...featureFlags,
                [constants.COMMANDS.FILL_FEEDER]: featureFlags.feed || featureFlags.fillFeeder,
                [constants.COMMANDS.COUNT_BEES]: featureFlags.scan,
                [constants.COMMANDS.MOVE_TO_VISIT_POSITION]: !bhome?.mode?.[constants.MODE.VISIT],
                [constants.COMMANDS.MOVE_TO_TRANSPORTATION_POSITION]: !bhome?.mode?.[constants.MODE.TRANSPORTATION],
            };
        }

        const isAvailable = featureFlags[featureName];

        if (isBoolean(isAvailable) && !isAvailable) {
            return false;
        }
    }

    if (!constants.FEATURE_FLAGS[featureName]) {
        return true;
    }

    const rolesOrder = [
        constants.ROLES.READ,
        constants.ROLES.WRITE,
        constants.ROLES.ADMIN,
        constants.ROLES.TECHNICIAN,
        constants.ROLES.SUPER_ADMIN,
        constants.ROLES.SUPER_VIEWER,
    ];
    return rolesOrder.indexOf(role) >= rolesOrder.indexOf(constants.FEATURE_FLAGS[featureName]);
};
