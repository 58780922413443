import { createSelector } from 'reselect';
import { getLatestTimestamp } from '../components/RightPanel/components/HivePanel/utils';

export const getCurrentBhome = state => state.beehome.currentBhome;

export const getCurrentBhomeId = state => state.beehome.currentBhome?.id;

export const getCurrentBhomeFrames = state => state.beehome?.frames ?? [];

export const getSelectedHives = state => state.beehome.selectedHives;

export const getIsHiveSelected = createSelector(
    getSelectedHives,
    (state, hiveId) => hiveId,
    (selectedHives, hiveId) => selectedHives.includes(hiveId)
);

export const getInspectedFrame = state => state.beehome.frameToInspect;

export const getInspectedHive = state => state.beehome.hiveToInspect;

export const getIsHiveInspected = createSelector(
    getInspectedHive,
    (state, hiveId) => hiveId,
    (inspectedHive, hiveId) => inspectedHive === hiveId
);

export const getInspectedFrameData = state => state.beehome.inspectedFrameData;

export const getCurrentBhomeFromList = createSelector(
    state => state.beehome.bhomes,
    (state, id) => id,
    (bhomes, id) => bhomes.find(item => item.id === id) || {}
);

export const getLastFeedDate = createSelector(getCurrentBhomeFrames, frames => {
    const feedTimestamps = frames.map(frame => frame?.sideA?.feedTimestamp).filter(Boolean);
    const lastFeedDate = getLatestTimestamp(feedTimestamps) ?? null;

    return lastFeedDate;
});

export const getCurrentBhomeFromListById = id => state => getCurrentBhomeFromList(state, Number(id));

export const getThermalScanData = state => state.bhome.thermalScanData;

export const getHistoryThermalScanData = state => state.bhome.historyThermalScanData;

export const getHiveThermalScanData = createSelector(
    [
        dataSource => dataSource,
        (_, station) => station,
        (_, __, from) => from,
        (_, __, ___, to) => to,
        (_, __, ___, ____, windowSize = 5) => windowSize,
    ],
    (dataSource, station, from, to, windowSize) => {
        const filteredData = dataSource?.filter(
            dataPoint => station === dataPoint.station && dataPoint.x >= from && dataPoint.x <= to
        );

        if (!filteredData?.length) {
            return [];
        }

        const meanRegulatedTemp =
            filteredData.reduce((acc, dataPoint) => acc + +dataPoint.irReading, 0) / filteredData.length;

        // Apply basic data with normalization
        const normalizedData = filteredData.map(dataPoint => ({
            ...dataPoint,
            normalizedTemp: dataPoint.irReading - meanRegulatedTemp,
        }));

        // Apply rolling average smoothing
        const smoothedData = normalizedData.map((point, index) => {
            const start = Math.max(0, index - Math.floor(windowSize / 2));
            const end = Math.min(normalizedData.length - 1, index + Math.floor(windowSize / 2));

            let sum = 0;
            let count = 0;

            for (let i = start; i <= end; i += 1) {
                sum += +normalizedData[i].irReading;
                count += 1;
            }

            return {
                ...point,
                irReading: count > 0 ? sum / count : point.irReading,
                normalizedTemp: count > 0 ? sum / count - meanRegulatedTemp : point.normalizedTemp,
            };
        });

        return smoothedData;
    }
);

export const getCurrentSnapshotDate = state => state.bhome.currentSnapshotDate;

export const getStationThermalMinMax = createSelector(
    [dataSource => dataSource, (_, station) => station],
    (dataSource, station) => {
        const stationData = dataSource?.filter(dataPoint => station === dataPoint.station);

        if (!stationData?.length) {
            return { yMin: 0, yMax: 0 };
        }

        const irReadings = stationData.map(d => +d.irReading);

        return {
            yMin: Math.min(...irReadings),
            yMax: Math.max(...irReadings),
        };
    }
);
