import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { merge } from 'lodash-es';
import GoogleMap from 'components/reusables/Map/components/Map';
import MapControls from 'components/reusables/Map/components/Map/MapControls';
import renderAdditionalMapMarkers from 'components/reusables/Map/components/AdditionalMapMarkers';
import useCreateDrop from 'components/reusables/Map/hooks/useCreateDrop';
import useClusters from 'components/views/Bhome/components/BhomeMap/hooks/useClusters';
import {
    drawMarkers,
    drawLocationMarkers,
    drawWorkspaceYardRanchClusters,
} from 'components/views/Bhome/components/BhomeMap/utils';
import { setCurrentBhome } from 'components/views/BeeHome/actions';
import usePolygonSelection from 'components/views/Workspace/components/Map/hooks/usePolygonSelection';
import constants from 'appConstants';
import { getBhomes, getLocations } from 'components/views/Dashboard/selectors';
import { getEntityGroupedData, getWorkspaceData, getWorkspaceRanches } from 'components/views/Workspace/selectors';
import { arrayOfObjectsShallowEqual } from '@beewise/react-utils/build/lib/comparison';
import useMapCentering from 'components/views/Bhome/components/BhomeMap/hooks/useMapCentering';
import { setEntityToInspect } from 'components/views/Workspace/actions';
import SearchBox from '../SearchBox';
import { updateMapBounds, ZOOM_LEVELS } from '../utils';

import './ReusableMap.scss';

const ReusableMap = ({
    handleEntityMapClick,
    selectedIds,
    handleCheckboxClick,
    hoveredEntities,
    hoveredEntity,
    clickedEntity,
    setHoveredEntities,
    setHoveredEntity,
    setSelectedIds,
    isBhomeMap,
    mapRef,
    handleMapChange,
    mapOptions,
    handleMapLoad,
    handleOnPlacesChange,
}) => {
    const dispatch = useDispatch();
    const mapContainerRef = useRef(null);
    const locations = useSelector(getLocations, arrayOfObjectsShallowEqual);
    const bhomes = useSelector(getBhomes, arrayOfObjectsShallowEqual);
    const ranches = useSelector(getWorkspaceRanches, arrayOfObjectsShallowEqual);
    const entities = useSelector(getEntityGroupedData, arrayOfObjectsShallowEqual);
    const workspaceData = useSelector(getWorkspaceData, arrayOfObjectsShallowEqual);
    const currentZoom = mapRef?.current?.getZoom();
    // eslint-disable-next-line no-console
    console.log(`Current Zoom Level: ${currentZoom}`);

    const { handleSetCreateDropMode, isCreateDropMode } = useCreateDrop({ mapRef });
    const {
        bhomeClusters,
        bhomeSuperClusters,
        locationClusters,
        yardClusters,
        yardSuperClusters,
        ranchClusters,
        ranchSuperClusters,
    } = useClusters({ workspaceData, bhomes, locations, mapOptions, ranches, yards: entities });

    const { handleCentering } = useMapCentering({ mapRef, locations });

    useEffect(() => {
        dispatch(setCurrentBhome({}));
    }, [dispatch]);

    const handleLocationClick = useCallback(
        location => {
            if (!mapRef.current) return;
            const bounds = new window.google.maps.LatLngBounds();
            const relatedEntities = entities.filter(entity => entity.id === location.ranch_id);
            if (relatedEntities.length) {
                relatedEntities.forEach(({ lat, lng }) => {
                    bounds.extend(new window.google.maps.LatLng(lat, lng));
                });
            } else {
                bounds.extend(new window.google.maps.LatLng(location.lat, location.lng));
            }
            mapRef.current.fitBounds(bounds);
            mapRef.current.setZoom(ZOOM_LEVELS.BHOME_MIN);
        },
        [entities, mapRef]
    );
    const selectedYards = useMemo(
        () => (selectedIds?.length ? entities.filter(entity => selectedIds.includes(entity.id)) : entities),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [selectedIds]
    );
    const onMapLoad = useCallback(
        ({ map: mapInstance }) => {
            handleMapLoad({ map: mapInstance });
            mapRef.current = mapInstance;
            updateMapBounds(mapInstance, entities);
        },
        [entities, handleMapLoad, mapRef]
    );

    const locationMarkers = drawLocationMarkers(locationClusters, mapRef.current, handleLocationClick);
    const bhomeMarkers = drawMarkers(bhomeClusters, bhomeSuperClusters, mapRef.current);
    const entityMarkers = drawWorkspaceYardRanchClusters({
        clusters: merge([], yardClusters, ranchClusters),
        supercluster: merge({}, yardSuperClusters, ranchSuperClusters),
        handleEntityMapClick,
        selectedIds,
        handleCheckboxClick,
        hoveredEntities,
        clickedEntity,
        setHoveredEntity,
        hoveredEntity,
        entities,
        mapContainerRef,
        currentZoom,
    });

    useEffect(() => {
        if (!mapRef.current || selectedIds?.length < 2) return;
        const mapInstance = mapRef.current;

        const bounds = new window.google.maps.LatLngBounds();

        selectedYards.forEach(entity => {
            const { lat, lng } = entity;
            bounds.extend(new window.google.maps.LatLng(lat, lng));
        });

        mapInstance.fitBounds(bounds);
    }, [mapRef, selectedIds?.length, selectedYards]);

    usePolygonSelection({
        entities,
        setSelectedIds,
        mapRef,
        setHoveredEntities,
    });

    const handleMouseMove = () => {
        hoveredEntity && setHoveredEntity(null);
        clickedEntity && dispatch(setEntityToInspect(null));
    };

    return (
        <section
            className={`map-wrapper ${isBhomeMap ? 'dashboard-map hybrid' : 'workspace-map'}`}
            ref={mapContainerRef}
            onMouseMove={handleMouseMove}
        >
            <GoogleMap onLoad={onMapLoad} onChange={handleMapChange}>
                {locationMarkers}
                {bhomeMarkers}
                {entityMarkers}
                {isBhomeMap &&
                    mapOptions?.zoom >= constants.SPLIT_ZOOM &&
                    renderAdditionalMapMarkers({ ranches, bounds: mapOptions.bounds })}
            </GoogleMap>
            {!!mapRef.current && (
                <>
                    {isBhomeMap && <SearchBox onPlacesChanged={handleOnPlacesChange} />}
                    <MapControls
                        map={mapRef.current}
                        handleAddLocation={handleSetCreateDropMode}
                        isAddingLocation={isCreateDropMode}
                        handleCentering={handleCentering}
                    />
                </>
            )}
        </section>
    );
};

ReusableMap.propTypes = {
    handleEntityMapClick: PropTypes.func,
    selectedIds: PropTypes.arrayOf(PropTypes.string),
    handleCheckboxClick: PropTypes.func,
    hoveredEntities: PropTypes.arrayOf(PropTypes.string),
    setHoveredEntity: PropTypes.func,
    clickedEntity: PropTypes.string,
    setHoveredEntities: PropTypes.func,
    hoveredEntity: PropTypes.string,
    setSelectedIds: PropTypes.func,
    isBhomeMap: PropTypes.bool,
    mapRef: PropTypes.shape(),
    handleMapChange: PropTypes.func,
    mapOptions: PropTypes.shape(),
    handleMapLoad: PropTypes.func,
    handleOnPlacesChange: PropTypes.func,
};

export default ReusableMap;
