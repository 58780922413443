import dayjs from 'dayjs';

const getTooltipWithLatestScanForAllFrames = ({ frames }) => {
    if (!frames.length) {
        return null;
    }

    let hiveLatestScan = 0;

    frames.forEach(frame => {
        if (!frame?.frameId) {
            return;
        }
        const scanTimestampA =
            Math.max(frame.sideA?.beeCount?.scanTimestamp ?? 0, frame.sideA?.scanTimestamp ?? 0) ?? 0;
        const scanTimestampB =
            Math.max(frame.sideB?.beeCount?.scanTimestamp ?? 0, frame.sideB?.scanTimestamp ?? 0) ?? 0;
        const latestScan = Math.max(scanTimestampA, scanTimestampB);

        if (latestScan > hiveLatestScan && !Number.isNaN(latestScan)) {
            hiveLatestScan = latestScan;
        }
    });

    if (!hiveLatestScan) {
        return null;
    }

    return `Over 14 days since last scan (${dayjs(hiveLatestScan).format('MMM D')})`;
};

export default getTooltipWithLatestScanForAllFrames;
