import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Tooltip from '@beewise/tooltip';
import MarkerIcon from '../MarkerIcon';
import GroupMarkerWrapper from '../GroupMarkerWrapper';
import { ZOOM_LEVELS } from '../utils';

const sumChildrenNodes = (supercluster, item) =>
    supercluster?.getChildren(item.id)?.reduce((acc, it) => {
        const { marker = {} } = it;
        if (it.id) {
            acc += sumChildrenNodes(supercluster, it);
        } else {
            acc += marker.bhome_ids?.length ?? 0;
        }
        return acc;
    }, 0);

const getClusterName = (supercluster, item, zoomLevel) => {
    try {
        const name = sumChildrenNodes(supercluster, item);
        return zoomLevel < ZOOM_LEVELS.BHOME_MIN ? `${name} BH` : name;
    } catch (err) {
        return zoomLevel < ZOOM_LEVELS.BHOME_MIN ? '0 BH' : '0';
    }
};

const getMarkerContent = ({ icon, supercluster, item, zoomLevel }) => {
    if (icon === 'blured-marker') {
        return {
            name: getClusterName(supercluster, item, zoomLevel),
            className: cn('bhome-unassigned', {
                'marker-large': zoomLevel < ZOOM_LEVELS.BHOME_MIN,
            }),
        };
    }
    return { icon, className: 'empty-location' };
};

const GroupMarker = ({ cluster, supercluster, type, map }) => {
    const zoomLevel = map ? map.getZoom() : 0;
    const { name, icon, className } = getMarkerContent({ icon: type, supercluster, item: cluster, zoomLevel });
    return (
        <GroupMarkerWrapper cluster={cluster} supercluster={supercluster} map={map}>
            {zoomLevel < ZOOM_LEVELS.BHOME_MIN ? (
                <Tooltip
                    content={
                        <>
                            Not associated to
                            <br />
                            any yard or ranch
                        </>
                    }
                    position="top center"
                    className="custom-workspace-tooltip"
                >
                    <MarkerIcon
                        icon={icon}
                        count={name || cluster?.properties?.point_count || 1}
                        className={className}
                    />
                </Tooltip>
            ) : (
                <MarkerIcon icon={icon} count={name || cluster?.properties?.point_count || 1} className={className} />
            )}
        </GroupMarkerWrapper>
    );
};

GroupMarker.propTypes = {
    cluster: PropTypes.shape(),
    supercluster: PropTypes.shape(),
    type: PropTypes.string,
    map: PropTypes.shape(),
};

export default GroupMarker;
