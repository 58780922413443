import React, { useCallback, useRef } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { arrayOfObjectsShallowEqual } from '@beewise/react-utils';
import useGranot from 'utils/isGranot';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import Tooltip from '@beewise/tooltip';
import { useLocation, useNavigate } from 'react-router-dom';
import constants from 'appConstants';
import { setBhomeArrived } from 'components/views/Dashboard/actions';
import { getBhomeUnreadAlerts } from 'components/reusables/Alerts/selectors';
import { getCurrentBhome } from 'components/views/BeeHome/selectors';
import AlertIcon from 'components/reusables/Alerts/AlertIcon';
import { toggleMap, toggleRightPanel } from 'actions';
import { setCurrentBhome, setZoomLevel } from 'components/views/BeeHome/actions';
import MarkerIcon from '../MarkerIcon';
import { ZOOM_LEVELS } from '../utils';
import './BhomeMarker.scss';

const getMarkerColor = (isSelectedBhome, isBhomeAssigned) => {
    if (isSelectedBhome && isBhomeAssigned) return '#00A3FF';
    if (isBhomeAssigned) return '#424242';
    return '#FFFFFF';
};

const BhomeMarker = ({ location, map }) => {
    const markerRef = useRef();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const locationPath = useLocation();
    const isGranot = useGranot();
    const zoomLevel = map ? map.getZoom() : 0;

    const isOldBhomePage = locationPath.pathname.includes('beehome');

    const bhomeUnreadAlerts = useSelector(
        state => getBhomeUnreadAlerts(state, location.bhome_ids),
        arrayOfObjectsShallowEqual
    );
    const currentBhome = useSelector(getCurrentBhome, shallowEqual);

    const handleMarkerClick = useCallback(() => {
        if (location.bhome_ids.length !== 1) {
            return;
        }
        dispatch(setZoomLevel({ zoomLevel }));
        if (currentBhome.id !== location.bhome_ids[0]) {
            const path = isOldBhomePage ? 'beehome' : 'bhome';
            navigate(`/${path}/${location.bhome_ids[0]}`);
        }
        // TODO remove with new map
        if (isOldBhomePage) {
            dispatch(setCurrentBhome({}));
            dispatch(toggleRightPanel(true));
            dispatch(toggleMap());
        }
    }, [location.bhome_ids, dispatch, zoomLevel, currentBhome.id, isOldBhomePage, navigate]);

    const handleAnimationEnd = useCallback(() => {
        if (location.movingToLocation && location.id) {
            dispatch(setBhomeArrived(location.id));
        }
    }, [location.id, location.movingToLocation, dispatch]);

    const isSelectedBhome = location?.bhome_ids?.includes?.(currentBhome?.id);

    const MarkerWrapComponent = Tooltip;
    const wrapComponentProps = {
        content: `#${location.bhome_ids[0]}`,
        position: 'top center',
        className: 'custom-workspace-tooltip',
    };

    const isBhomeAssigned = location.ranch_id || location.yard_id;

    const markerColor = getMarkerColor(isSelectedBhome, isBhomeAssigned);

    return (
        <MarkerWrapComponent {...wrapComponentProps}>
            <MarkerIcon
                onClick={handleMarkerClick}
                onAnimationEnd={handleAnimationEnd}
                className={cx({
                    'marker-animated': location.movingToLocation,
                    'is-selected': isSelectedBhome,
                    'marker-large': !!location?.bhome_ids?.length && zoomLevel > ZOOM_LEVELS.BHOME_MIN,
                    'bhome-unassigned': !location.ranch_id && !location.yard_id,
                    'bhome-assigned': isBhomeAssigned,
                })}
                icon="beehome-inverted"
                count={location?.bhome_ids?.length > 1 ? location?.bhome_ids?.length : null}
                ref={markerRef}
                color={markerColor}
            >
                {!isGranot && !!bhomeUnreadAlerts.length && (
                    <AlertIcon
                        className="marker-alert"
                        alerts={bhomeUnreadAlerts}
                        type={constants.ALERT_COMPONENT_LEVEL.BHOME}
                    />
                )}
            </MarkerIcon>
        </MarkerWrapComponent>
    );
};

BhomeMarker.propTypes = {
    location: PropTypes.shape(),
    map: PropTypes.shape(),
};

export default BhomeMarker;
