import dayjs from 'dayjs';
import constants from 'appConstants';

const FILTER_TYPES = {
    REGIONS: 'regions',
    ACTIVE_ONLY: 'activeOnly',
    FEED: 'feed',
    SITE_TYPE: 'siteType',
};

export const actionsValues = {
    TRANSPORT_ACTION: constants.MODE.TRANSPORTATION,
    VISIT_ACTION: constants.MODE.VISIT,
    FEED_ACTION: 'feed',
};

export const FEED_TIME_PERIODS = {
    ACTIVE_NOW: 'activeNow',
    PAST_WEEK: 'pastWeek',
    MORE_THAN_A_WEEK: 'moreThanAWeek',
};

const ONE_WEEK = 1;
const WEEK_UNIT = 'week';

const isAfterWeekAgo = date => date && dayjs(date).isAfter(dayjs().subtract(ONE_WEEK, WEEK_UNIT));
const isBeforeWeekAgo = date => date && dayjs(date).isBefore(dayjs().subtract(ONE_WEEK, WEEK_UNIT));
const isActiveNow = date => !date;

const checkLastFeedTimeConditions = {
    all: lastFeedTime => !!lastFeedTime,
    pastWeek: isAfterWeekAgo,
    moreThanWeek: isBeforeWeekAgo,
    activeNowAndPastWeek: isAfterWeekAgo,
    activeNowAndMoreThanWeek: isBeforeWeekAgo,
    pastWeekAndMoreThanWeek: lastFeedTime =>
        isAfterWeekAgo(lastFeedTime) || (lastFeedTime && isBeforeWeekAgo(lastFeedTime)),
};

const checkActiveActions = (actions, condition) => actions?.some(condition);
// lastFeedTime - is saved in frame side after SUCCESSFULL feed action,  use lastFeedTime only if there are no actions from workspace interface
const checkShouldUseLastFeedTime = (workspaceActions, conditionResult) => !workspaceActions.length && conditionResult;

// actions created throught workspace interface, other actions (bhome level, autofeed) are not reflected here
const createActionConditions = actionType => ({
    all: action => action.action === actionType,
    activeNow: action => action.action === actionType && isActiveNow(action.end_time),
    pastWeek: action => action.action === actionType && isAfterWeekAgo(action.end_time),
    moreThanWeek: action => action.action === actionType && isBeforeWeekAgo(action.end_time),
    activeNowAndPastWeek: action =>
        action.action === actionType && (isActiveNow(action.end_time) || isAfterWeekAgo(action.end_time)),
    activeNowAndMoreThanWeek: action =>
        action.action === actionType && (isActiveNow(action.end_time) || isBeforeWeekAgo(action.end_time)),
    pastWeekAndMoreThanWeek: action =>
        action.action === actionType && (isAfterWeekAgo(action.end_time) || isBeforeWeekAgo(action.end_time)),
});

const createFilterConditions = {
    all: (yard, actionType) =>
        checkActiveActions(yard.workspaceActions, createActionConditions(actionType).all) ||
        checkShouldUseLastFeedTime(yard.workspaceActions, checkLastFeedTimeConditions.all(yard?.lastFeedTime)),
    activeNowAndPastWeek: (yard, actionType) =>
        checkActiveActions(yard.workspaceActions, createActionConditions(actionType).activeNowAndPastWeek) ||
        checkShouldUseLastFeedTime(
            yard.workspaceActions,
            checkLastFeedTimeConditions.activeNowAndPastWeek(yard?.lastFeedTime)
        ),
    activeNowAndMoreThanWeek: (yard, actionType) =>
        checkActiveActions(yard.workspaceActions, createActionConditions(actionType).activeNowAndMoreThanWeek) ||
        checkShouldUseLastFeedTime(
            yard.workspaceActions,
            checkLastFeedTimeConditions.activeNowAndMoreThanWeek(yard?.lastFeedTime)
        ),
    pastWeekAndMoreThanWeek: (yard, actionType) =>
        checkActiveActions(yard.workspaceActions, createActionConditions(actionType).pastWeekAndMoreThanWeek) ||
        checkShouldUseLastFeedTime(
            yard.workspaceActions,
            checkLastFeedTimeConditions.pastWeekAndMoreThanWeek(yard?.lastFeedTime)
        ),
    activeNow: (yard, actionType) =>
        checkActiveActions(yard.workspaceActions, createActionConditions(actionType).activeNow),
    pastWeek: (yard, actionType) =>
        checkActiveActions(yard.workspaceActions, createActionConditions(actionType).pastWeek) ||
        checkShouldUseLastFeedTime(yard.workspaceActions, checkLastFeedTimeConditions.pastWeek(yard?.lastFeedTime)),
    moreThanWeek: (yard, actionType) =>
        checkActiveActions(yard.workspaceActions, createActionConditions(actionType).moreThanWeek) ||
        checkShouldUseLastFeedTime(yard.workspaceActions, checkLastFeedTimeConditions.moreThanWeek(yard?.lastFeedTime)),
};

export const filterFeedActions = (filter, yard, actionType) => {
    const { activeNow, pastWeek, moreThanAWeek } = filter;
    if (activeNow && pastWeek && moreThanAWeek) {
        return createFilterConditions.all(yard, actionType);
    } else if (activeNow && pastWeek) {
        return createFilterConditions.activeNowAndPastWeek(yard, actionType);
    } else if (activeNow && moreThanAWeek) {
        return createFilterConditions.activeNowAndMoreThanWeek(yard, actionType);
    } else if (pastWeek && moreThanAWeek) {
        return createFilterConditions.pastWeekAndMoreThanWeek(yard, actionType);
    } else if (activeNow) {
        return createFilterConditions.activeNow(yard, actionType);
    } else if (pastWeek) {
        return createFilterConditions.pastWeek(yard, actionType);
    } else if (moreThanAWeek) {
        return createFilterConditions.moreThanWeek(yard, actionType);
    }

    return true;
};

const filterPredicates = {
    checkRegionFilter: (entity, regions) => {
        if (!regions?.length) return true;
        return entity.regionName && regions.includes(entity.regionName);
    },
    checkActiveFilter: (entity, filter) => (filter ? !!entity?.bhomes?.length : true),
    checkFeedActionFilter: (entity, feedActionFilter) => {
        if (!feedActionFilter) return true;
        return filterFeedActions(feedActionFilter, entity, actionsValues.FEED_ACTION);
    },
    checkSiteTypeFilter: (entity, siteType) => {
        if (!siteType) return true;

        const { ranch: isRanchSelected, yard: isYardSelected } = siteType;

        if (!isRanchSelected && !isYardSelected) return true;

        return (isRanchSelected && entity.isRanch) || (isYardSelected && !entity.isRanch);
    },
};

const composeFilters =
    (...filters) =>
    entity =>
        filters.every(filter => filter(entity));

export const filterEntities = filter => {
    if (!filter) return () => true;

    const filters = [
        entity => filterPredicates.checkRegionFilter(entity, filter[FILTER_TYPES.REGIONS]),
        entity => filterPredicates.checkActiveFilter(entity, filter[FILTER_TYPES.ACTIVE_ONLY]),
        entity => filterPredicates.checkFeedActionFilter(entity, filter[FILTER_TYPES.FEED]),
        entity => filterPredicates.checkSiteTypeFilter(entity, filter[FILTER_TYPES.SITE_TYPE]),
    ];

    return composeFilters(...filters);
};
