import { shallowEqual, useSelector } from 'react-redux';
import { getUser } from 'selectors';
import { getIsSuperAdmin } from '../../components/views/Admin/selectors';

export default () => {
    const user = useSelector(getUser, shallowEqual);
    const isSuperAdmin = useSelector(getIsSuperAdmin);

    return (user.companyName === 'Beewise for Granot' || user.companyName === 'sanad AD') && !isSuperAdmin;
};
