import { isEmpty } from 'lodash-es';
import {
    FETCH_WORKSPACE_DATA,
    FETCH_CREATE_YARD_ACTION,
    FETCH_UPDATE_YARD_ACTION,
    FETCH_GET_YARD_ACTION,
    CHANGE_ENTITY_FILTER,
    CLEAR_ENTITY_FILTER,
    CHANGE_YARD_SORT,
    SET_ENTITY_TO_INSPECT,
    FETCH_GET_YARD_WORKSPACE_EVENTS_BY_BHOME_IDS,
    FETCH_GET_RANCH_WORKSPACE_EVENTS_BY_BHOME_IDS,
    CLEAR_WORKSPACE_EVENTS,
    FETCH_WORKSPACE_RANCHES,
} from '../actionTypes';
import { actionsValues, SORT_WORKSPACE_OPTIONS } from '../utils';
import { FETCH_DELETE_YARD } from '../../BeeHome/actionTypes';

const defaultFilterState = {
    activeOnly: true,
    [actionsValues.FEED_ACTION]: {
        activeNow: false,
        pastWeek: false,
        moreThanAWeek: false,
    },
    regions: [],
    siteType: [],
};

const initialState = {
    data: [],
    isFetched: false,
    filter: defaultFilterState,
    sort: SORT_WORKSPACE_OPTIONS[0].value,
    workspaceActions: [],
    inspectedEntity: null,
    yardEvents: [],
    ranchEvents: [],
    ranches: [],
};

const workspace = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_WORKSPACE_DATA.success:
            return {
                ...state,
                data: action.data.result,
                isFetched: true,
            };

        case FETCH_DELETE_YARD.success:
            return {
                ...state,
                data: state.data.filter(yard => yard.id !== action.data.yard.id),
            };
        case CHANGE_ENTITY_FILTER:
            return {
                ...state,
                filter: {
                    ...state.filter,
                    ...action.filter,
                },
            };
        case CLEAR_ENTITY_FILTER:
            return {
                ...state,
                filter: defaultFilterState,
            };
        case CHANGE_YARD_SORT:
            return {
                ...state,
                sort: action.sort,
            };
        case FETCH_UPDATE_YARD_ACTION.success:
        case FETCH_CREATE_YARD_ACTION.success:
            return {
                ...state,
                workspaceActions: [...state.workspaceActions, ...action.data.workspaceActions],
            };
        case FETCH_GET_YARD_ACTION.success:
            return {
                ...state,
                workspaceActions: action.data.workspaceActions,
            };
        case SET_ENTITY_TO_INSPECT: {
            const data = isEmpty(action.entity) ? { inspectedEntity: {} } : {};
            return {
                ...state,
                ...data,
                inspectedEntity: action.entity,
            };
        }
        case FETCH_GET_YARD_WORKSPACE_EVENTS_BY_BHOME_IDS.success:
            return {
                ...state,
                yardEvents: action.data.yardEvents ?? [],
            };
        case FETCH_GET_RANCH_WORKSPACE_EVENTS_BY_BHOME_IDS.success:
            return {
                ...state,
                ranchEvents: action.data.ranchEvents ?? [],
            };
        case CLEAR_WORKSPACE_EVENTS:
            return {
                ...state,
                yardEvents: [],
                ranchEvents: [],
            };
        case FETCH_WORKSPACE_RANCHES.success:
            return {
                ...state,
                ranches: action.data,
            };

        default:
            return state;
    }
};

export default workspace;
