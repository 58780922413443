import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { faEllipsisV } from '@fortawesome/pro-regular-svg-icons';
import IconButton from '@beewise/icon-button';
import Tooltip from '@beewise/tooltip';
import './Menu.scss';

const renderMenuItem = ({ label, onClick, isDisabled, tooltip, className }, index) => {
  const menuItem = (
    <div
      role="button"
      className={`menu-item ${isDisabled ? 'disabled' : ''} ${className || ''}`}
      onClick={!isDisabled ? onClick : undefined}
      key={`${label}-${index}`}
      tabIndex={0}
    >
      {label}
    </div>
  );

  if (tooltip) {
    return (
      <Tooltip
        key={`${label}-${index}`}
        content={tooltip}
        action="hover"
        position="left"
        showOnScroll={false}
      >
        {menuItem}
      </Tooltip>
    );
  }

  return menuItem;
};

const Menu = forwardRef(
  (
    { options = [], position = 'bottom left', customComponent, iconType, variant = 'primary' },
    ref
  ) => {
    const menuContent = options.map((option, index) => renderMenuItem(option, index));

    return (
      <Tooltip
        action="click"
        isInverted
        showOnScroll
        position={position}
        rootId="menu-root"
        className={`menu menu-${variant}`}
        ref={ref}
        content={menuContent}
      >
        {customComponent || <IconButton icon={faEllipsisV} className="icon" type={iconType} />}
      </Tooltip>
    );
  }
);

Menu.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
      isDisabled: PropTypes.bool,
      tooltip: PropTypes.string,
      className: PropTypes.string
    })
  ),
  position: PropTypes.oneOf([
    'top',
    'top right',
    'top left',
    'bottom',
    'bottom right',
    'bottom left',
    'left',
    'left top',
    'left bottom',
    'right',
    'right top',
    'right bottom'
  ]),
  customComponent: PropTypes.node,
  iconType: PropTypes.string,
  variant: PropTypes.oneOf(['primary', 'secondary'])
};

export default Menu;
