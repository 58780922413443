import globalConstants from '@beewise/constants';

export default {
    ...globalConstants,
    FEATURE_FLAGS: {
        scan: globalConstants.ROLES.READ,
        moveFrames: globalConstants.ROLES.WRITE,
        cancelActivities: globalConstants.ROLES.WRITE,
        changeStatus: globalConstants.ROLES.WRITE,
        addLocation: globalConstants.ROLES.WRITE,
        feed: globalConstants.ROLES.WRITE,
        deleteUser: globalConstants.ROLES.ADMIN,
    },
    COMMANDS: {
        ...globalConstants.COMMANDS,
        HIVE_MANAGEMENT: 'hiveManagement',
    },
    HARVESTING_LEVEL_ERROR: 95,
    SYRUP_LEVEL_ERROR: 20,
    BEE_MAP_LIMIT: 80,
    ALERT_PRIORITY: {
        LOW_PRIORITY: 'lowPriority',
        HIGH_PRIORITY: 'highPriority',
        MEDIUM_PRIORITY: 'mediumPriority',
    },
    ALERT_COMPONENT_LEVEL: {
        GENERAL: 'general',
        BHOME: 'bhome',
        FRAME: 'frame',
    },
    SYRUP_GALLONS: 20,
    SPLIT_ZOOM: 14,
    DEFAULT_ZOOM: 16,
    SYRUP_FEED: 2500,
    SYRUP_POSITIVE_WEIGHT: 1800,
    RATIO_GAL_TO_GRAMM: 0.0001902,
};
