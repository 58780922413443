import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { useForm, useWatch } from 'react-hook-form';
import { isEmpty, omit } from 'lodash-es';
import { useDispatch } from 'react-redux';
import Button from '@beewise/button';
import Tooltip from '@beewise/tooltip';
import { InputFormField, SelectFormField } from '@beewise/hook-form';
import { faCircleXmark } from '@fortawesome/pro-light-svg-icons';
import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useToggle } from '@beewise/react-utils';
import { deleteNewRegion, fetchCreateRegion, fetchDeleteRegion, fetchUpdateRegion } from '../../../actions';
import RegionListModal from './RegionListModal';

const RegionListItem = ({
    region,
    isCreate,
    yards = [],
    availableYards = [],
    ranches = [],
    availableRanches = [],
    isDefault,
}) => {
    const [isModalOpened, toggleModal] = useToggle();
    const dispatch = useDispatch();
    const yardsOptions = yards.map(yard => ({
        label: yard.name,
        value: yard.id,
        disabled: !availableYards.some(item => item.id === yard.id),
    }));
    const ranchesOptions = ranches.map(ranch => ({
        label: ranch.name,
        value: ranch.id,
        disabled: !availableRanches.some(item => item.id === ranch.id),
    }));

    const { control, setValue } = useForm({
        defaultValues: {
            data: {},
            yards: [],
            ranches: [],
            ...region,
        },
    });

    const values = useWatch({ control });

    const handleEditRegion = data => {
        const { id, ...rest } = data;
        dispatch(
            fetchUpdateRegion(id, { ...omit(rest, ['created_at', 'updated_at', 'bhomes', 'company_id', 'is_default']) })
        );
    };

    const handleCreateRegion = data => {
        const { id, ...rest } = data;
        dispatch(
            fetchCreateRegion({
                name: rest.name,
                yards: rest.yards,
                ranches: rest.ranches,
                data: {},
            })
        );
    };

    const onSubmit = data => (isCreate ? handleCreateRegion : handleEditRegion)(data);

    const handleOnBlur = () => {
        if (values?.name) {
            onSubmit(values);
        }
    };

    const handleOnChange = name => e => {
        setValue(name, e);
        onSubmit({
            ...values,
            [name]: e,
        });
    };

    const handleDeleteRegion = () => {
        if (values?.id) {
            dispatch(fetchDeleteRegion(values?.id));
        } else {
            dispatch(deleteNewRegion());
        }
    };

    const hasSomeStrengthData = useMemo(() => {
        if (isEmpty(values?.data)) {
            return false;
        }

        return Object.values(values?.data).every(item => item.brood && item.bee);
    }, [values]);

    const onModalSave = () => {
        onSubmit(values);
        toggleModal();
    };

    return (
        <div className={cx('regions-list-item', { 'regions-list-item-default': isDefault })}>
            {!isDefault && (
                <>
                    <InputFormField required name="name" control={control} label="Region name" onBlur={handleOnBlur} />
                    <SelectFormField
                        name="yards"
                        control={control}
                        label="Yards"
                        options={yardsOptions}
                        isMulti
                        maxMenuHeight={200}
                        onChange={handleOnChange('yards')}
                        isOptionDisabled={option => option.disabled}
                    />
                    <SelectFormField
                        name="ranches"
                        control={control}
                        label="Ranches"
                        options={ranchesOptions}
                        isMulti
                        maxMenuHeight={200}
                        onChange={handleOnChange('ranches')}
                        isOptionDisabled={option => option.disabled}
                    />
                </>
            )}
            <Button className="regions-list-item-strength secondary-blue" onClick={toggleModal}>
                {hasSomeStrengthData && <FontAwesomeIcon className="hive-strength-checkmark" icon={faCircleCheck} />}
                {isDefault ? 'Set Default Hive Strength' : 'Set Hive Strength'}
            </Button>
            {!isDefault && (
                <Tooltip content="Delete" position="top center">
                    <FontAwesomeIcon
                        className="regions-list-item-delete"
                        icon={faCircleXmark}
                        onClick={handleDeleteRegion}
                    />
                </Tooltip>
            )}
            <RegionListModal
                isModalOpened={isModalOpened}
                toggleModal={toggleModal}
                control={control}
                onSave={onModalSave}
                values={values}
                setValue={setValue}
            />
        </div>
    );
};

RegionListItem.propTypes = {
    region: PropTypes.shape(),
    isCreate: PropTypes.bool,
    isDefault: PropTypes.bool,
    yards: PropTypes.arrayOf(PropTypes.shape()),
    ranches: PropTypes.arrayOf(PropTypes.shape()),
    availableYards: PropTypes.arrayOf(PropTypes.shape()),
    availableRanches: PropTypes.arrayOf(PropTypes.shape()),
};

export default RegionListItem;
