export const getIsVisibleInsideScroll = (content, container) => {
  const { top: contentTop, bottom: contentBottom } = content;
  const { top: containerTop, bottom: containerBottom } = container;

  return contentTop >= containerTop && contentBottom <= containerBottom;
};
export const getCoordinatesForPosition = (
  triggerBounding,
  ContentBounding,
  position,
  scrollContainer,
  isHoverable,
  recursionCount = 0
) => {
  if (recursionCount > 2) {
    console.warn('Maximum position recalculation depth reached');
    return {
      top: triggerBounding.top,
      left: triggerBounding.left,
      transform: ''
    };
  }

  const margin = isHoverable ? 0 : 6;
  const positionOptions = position.split(' ');
  const CenterTop = triggerBounding.top + triggerBounding.height / 2;
  const CenterLeft = triggerBounding.left + triggerBounding.width / 2;
  const { height, width } = ContentBounding;
  let top = CenterTop - height / 2;
  let left = CenterLeft - width / 2;
  const transform = '';

  switch (positionOptions[0]) {
    case 'top':
      top -= height / 2 + triggerBounding.height / 2 + margin;
      break;
    case 'bottom':
      top += height / 2 + triggerBounding.height / 2 + margin;
      break;
    case 'left':
      left -= width / 2 + triggerBounding.width / 2 + margin;
      break;
    case 'right':
      left += width / 2 + triggerBounding.width / 2 + margin;
      break;
    default:
  }
  switch (positionOptions[1]) {
    case 'top':
      top = triggerBounding.top;
      break;
    case 'bottom':
      top = triggerBounding.top - height + triggerBounding.height;
      break;
    case 'left':
      left = triggerBounding.left;
      break;
    case 'right':
      left = triggerBounding.left - width + triggerBounding.width;
      break;
    default:
  }

  if (!scrollContainer) {
    return { top, left, transform };
  }

  const contentBottom = top + height;
  const isInsideScroll = getIsVisibleInsideScroll({ top, bottom: contentBottom }, scrollContainer);

  if (!isInsideScroll) {
    const shouldChangePositionToTop =
      positionOptions[0] === 'bottom' && contentBottom >= scrollContainer.bottom;
    const shouldChangePositionToBottom = positionOptions[0] === 'top' && top <= scrollContainer.top;

    if (shouldChangePositionToBottom || shouldChangePositionToTop) {
      const newPosition = shouldChangePositionToTop ? 'top' : 'bottom';
      return getCoordinatesForPosition(
        triggerBounding,
        ContentBounding,
        `${newPosition} ${positionOptions[1]}`,
        scrollContainer,
        isHoverable,
        recursionCount + 1
      );
    }
  }

  return { top, left, transform };
};
export const findScrollContainer = (element) => {
  if (!element) {
    return document.documentElement;
  }

  let parent = element.parentElement;

  while (parent) {
    const { overflow } = window.getComputedStyle(parent);
    const overflowValues = overflow.split(' ');

    if (overflowValues.some((o) => o === 'auto' || o === 'scroll')) {
      return parent;
    }

    parent = parent.parentElement;
  }

  return document.documentElement;
};
