import React, { useCallback, useEffect, useState, useImperativeHandle, forwardRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { arrayOfObjectsShallowEqual } from '@beewise/react-utils';
import { useDispatch, useSelector } from 'react-redux';
import {
    setCurrentSnapshotDate,
    setHistoryThermalScanData,
    fetchThermalScanData,
} from 'components/views/Bhome/actions';
import { ALL_ACTIONS } from 'components/views/Bhome/components/RightPanel/components/ActionPanel/utils';
import {
    getMessagesDatesInRange,
    getAreSnapshotsFetched,
    getIsHistoryLayoutSnapshotShown,
    getSnapshots,
} from 'components/views/BeeHome/selectors';
import { getIsRightPanelShown } from 'selectors';
import { fetchLayoutSnapshot, setSnapshotToShow, fetchFrames } from 'components/views/BeeHome/actions';

import './HistorySlider.scss';

const MIN = 0;
const DATE_FORMAT = 'YYYY-MM-DD';
const UI_DATE_FORMAT = 'DD MMM';

const HistorySlider = forwardRef(({ bhomeId, setIsHistoryView, handleOpenActionPanel }, ref) => {
    const dispatch = useDispatch();
    const [sliderValue, setSliderValue] = useState(MIN);
    const isHistoryLayoutSnapshotShown = useSelector(getIsHistoryLayoutSnapshotShown);
    const areSnapshotsFetched = useSelector(getAreSnapshotsFetched);
    const isActionPanelOpen = useSelector(getIsRightPanelShown);
    const snapshots = useSelector(getSnapshots, arrayOfObjectsShallowEqual);
    // we taking lastItemIndex as length since allDates got today date on top of snapshots dates
    const lastItemIndex = snapshots?.length ?? MIN;
    const today = dayjs().format(DATE_FORMAT);

    const snapshotDates = [
        ...snapshots.map(snapshot => dayjs(snapshot.createdAt).subtract(1, 'day').format(DATE_FORMAT)),
    ];

    const allDates = [...snapshotDates, today];

    const messagesDates = useSelector(
        state =>
            snapshotDates?.length ? getMessagesDatesInRange(state, snapshotDates[0], today, ALL_ACTIONS.value) : [],
        arrayOfObjectsShallowEqual
    );

    const messagesDatesSet = useMemo(() => new Set(messagesDates), [messagesDates]);

    const markerPositions = allDates.reduce((acc, date, index) => {
        if (messagesDatesSet.has(date)) {
            acc.push({
                date,
                position: (index / lastItemIndex) * 99,
            });
        }
        return acc;
    }, []);

    useEffect(() => {
        if (!bhomeId) {
            return;
        }

        dispatch(fetchLayoutSnapshot(bhomeId));
    }, [bhomeId, dispatch]);

    useEffect(() => {
        if (!bhomeId || !snapshots.length) {
            return;
        }

        dispatch(fetchThermalScanData(bhomeId));
    }, [bhomeId, dispatch, snapshots.length]);

    useEffect(() => {
        if (isHistoryLayoutSnapshotShown || sliderValue === MIN) {
            return;
        }

        setSliderValue(MIN);
    }, [isHistoryLayoutSnapshotShown, sliderValue]);

    const updateSlider = useCallback(
        value => {
            setSliderValue(value);

            const isTodayView = value === MIN;
            const isHistoryView = !isTodayView;

            setIsHistoryView(isHistoryView);

            if (isTodayView) {
                dispatch(fetchFrames(bhomeId));
                dispatch(fetchThermalScanData(bhomeId));
                dispatch(setCurrentSnapshotDate({ date: today }));
            } else {
                const index = lastItemIndex - value;
                const snapshot = snapshots[index];
                const snapshotDate = snapshot?.createdAt
                    ? dayjs(snapshot.createdAt).subtract(1, 'day').format(DATE_FORMAT)
                    : null;

                if (isHistoryView && !isActionPanelOpen) {
                    handleOpenActionPanel();
                }

                dispatch(setSnapshotToShow(snapshot || null));
                dispatch(
                    setHistoryThermalScanData({
                        historyThermalScanData: snapshot?.data?.thermalScanData || [],
                    })
                );
                dispatch(setCurrentSnapshotDate({ date: snapshotDate }));
            }
        },
        [bhomeId, dispatch, handleOpenActionPanel, isActionPanelOpen, lastItemIndex, setIsHistoryView, snapshots, today]
    );

    const handleSliderChange = useCallback(
        e => {
            const value = Number(e.target.value);
            updateSlider(value);
        },
        [updateSlider]
    );

    useImperativeHandle(ref, () => ({
        selectLastElement: () => updateSlider(MIN),
    }));

    const index = lastItemIndex - sliderValue;
    const tooltipDate =
        sliderValue === MIN
            ? today && dayjs(today).format(UI_DATE_FORMAT)
            : snapshots?.[index]?.createdAt &&
              dayjs(snapshots[index].createdAt).subtract(1, 'day').format(UI_DATE_FORMAT);
    const shouldShowTooltip = sliderValue !== MIN && sliderValue !== lastItemIndex;
    const oldestSnapshotDate = snapshotDates[0] && dayjs(snapshotDates[0]).format(UI_DATE_FORMAT);
    const newestSnapshotDate = dayjs(today).format(UI_DATE_FORMAT);
    const tooltipStyle = {
        left: `${((lastItemIndex - sliderValue) / lastItemIndex) * 100}%`,
    };
    const sliderTrackStyle = {
        '--slider-value': `${((sliderValue - MIN) / lastItemIndex) * 100}%`,
    };

    if (!snapshots.length) {
        return null;
    }

    return (
        <div className="hives-history-slider">
            <span className="slider-value-display">{oldestSnapshotDate}</span>
            <div className="slider-container" style={sliderTrackStyle}>
                <input
                    disabled={!areSnapshotsFetched}
                    min={MIN}
                    max={lastItemIndex}
                    type="range"
                    onChange={handleSliderChange}
                    value={sliderValue}
                />
                {shouldShowTooltip && (
                    <div className="slider-tooltip" style={tooltipStyle}>
                        {tooltipDate}
                    </div>
                )}
                <div className="slider-markers">
                    {markerPositions.map((marker, index) => (
                        <div
                            key={`${marker.position}${index}`}
                            className="slider-marker"
                            style={{ left: `${marker.position}%` }}
                        />
                    ))}
                </div>
            </div>
            <span className="slider-value-display">{newestSnapshotDate}</span>
        </div>
    );
});

HistorySlider.propTypes = {
    bhomeId: PropTypes.number,
    setIsHistoryView: PropTypes.func.isRequired,
    handleOpenActionPanel: PropTypes.func,
};

export default HistorySlider;
