import React from 'react';
import PropTypes from 'prop-types';

const Label = ({ color = '#000' }) => (
  <svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.4794 11.2281L16.9651 6L13.4794 0.771945C13.4237 0.688338 13.3481 0.61978 13.2595 0.572345C13.1709 0.524909 13.072 0.50006 12.9714 0.5H1.68728C1.52521 0.5 1.36977 0.564385 1.25516 0.67899C1.14056 0.793596 1.07617 0.949034 1.07617 1.11111V10.8889C1.07617 11.051 1.14056 11.2064 1.25516 11.321C1.36977 11.4356 1.52521 11.5 1.68728 11.5H12.9714C13.072 11.4999 13.1709 11.4751 13.2595 11.4277C13.3481 11.3802 13.4237 11.3117 13.4794 11.2281Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

Label.propTypes = {
  color: PropTypes.string
};

export default Label;
