export const SIZES = {
  NORMAL: 'normal',
  SMALL: 'small',
  XSMALL: 'xsmall'
};

export const SIZE_VALUES = {
  [SIZES.NORMAL]: 48,
  [SIZES.SMALL]: 40,
  [SIZES.XSMALL]: 30
};

export const TYPES = {
  TEXT: 'text',
  PASSWORD: 'password',
  PERCENTAGE: 'percentage',
  FILE: 'file',
  SEARCH: 'search'
};

export const POSITIONS = {
  LEFT: 'left',
  RIGHT: 'right'
};

export const getFieldValue = (type, e) =>
  type === TYPES.FILE ? e.target.files?.[0] : e.target.value;
export const getInitialActiveState = ({ value, inputRef }) =>
  (value !== undefined && value !== null && value !== '') || inputRef.current?.value;

export const getDisplayValue = ({ type, value }) => {
  switch (type) {
    case TYPES.PERCENTAGE:
      return `${value}%`;
    case TYPES.FILE:
      return value?.fileName;
    default:
      return value;
  }
};

export const getCurrentInputType = ({ isPasswordShown }) =>
  isPasswordShown ? TYPES.TEXT : TYPES.PASSWORD;

export const getIsWithIcon = ({ type, inputProps }) =>
  type === TYPES.PASSWORD || inputProps?.component;
