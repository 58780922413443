import PropTypes from 'prop-types';
import { SIZES, TYPES } from './utils';

export default {
  error: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  required: PropTypes.bool,
  value: PropTypes.string,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  multiline: PropTypes.bool,
  size: PropTypes.oneOf(Object.values(SIZES)),
  iconLeft: PropTypes.element,
  iconRight: PropTypes.element,
  info: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.oneOf(Object.values(TYPES)),
  className: PropTypes.string
};
