import React, { forwardRef } from 'react';
import TextField from './BaseTextField';
import PasswordField from './PasswordField';
import SearchField from './SearchField';
import { TYPES } from './utils';
import props from './props';

const FIELD_COMPONENTS = {
  [TYPES.TEXT]: TextField,
  [TYPES.PASSWORD]: PasswordField,
  [TYPES.SEARCH]: SearchField
};

const GeneralTextField = forwardRef(({ type = TYPES.TEXT, ...props }, ref) => {
  const Component = FIELD_COMPONENTS[type] || TextField;
  return <Component {...props} ref={ref} />;
});

GeneralTextField.propTypes = props;

export default GeneralTextField;
