import React, { forwardRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/pro-regular-svg-icons';
import cn from 'classnames';
import { getCurrentInputType } from '../utils';
import usePasswordVisibility from './usePasswordVisibility';
import props from '../props';
import TextField from '../BaseTextField';

const PasswordField = forwardRef((props, ref) => {
  const { isPasswordShown, togglePasswordVisibility } = usePasswordVisibility();
  const currentInputType = getCurrentInputType({ isPasswordShown });

  return (
    <TextField
      {...props}
      type={currentInputType}
      className={cn('password-field', props.className)}
      ref={ref}
      iconRight={
        <FontAwesomeIcon
          icon={isPasswordShown ? faEyeSlash : faEye}
          onClick={togglePasswordVisibility}
          className="password-icon"
        />
      }
    />
  );
});

PasswordField.propTypes = props;

export default PasswordField;
