import { SHIFT } from 'components/views/Workspace/utils';
import { useEffect, useRef, useCallback } from 'react';
import { isEqual, uniq } from 'lodash-es';
import { POLYGON_PARAMS } from 'components/reusables/Map/components/constants';

const SMALL_LAT_LNG_INCREMENT = 0.0001;

const usePolygonSelection = ({ yards, setSelectedIds, mapRef, setHoveredEntities }) => {
    const polygonRef = useRef(null);
    const polygonPathRef = useRef([]);
    const shiftPressedRef = useRef(false);

    const updateHoveredYards = useCallback(
        newPolygonPath => {
            if (!polygonRef.current || newPolygonPath.length !== 4) {
                return;
            }
            const polygon = new window.google.maps.Polygon({ paths: newPolygonPath });
            const hovered = yards?.filter(yard =>
                window.google.maps.geometry.poly.containsLocation(
                    new window.google.maps.LatLng(yard.lat, yard.lng),
                    polygon
                )
            );
            const hoveredIds = [...new Set(hovered?.map(yard => yard.id))];
            setHoveredEntities(prevIds => (isEqual(prevIds, hoveredIds) ? prevIds : hoveredIds));
        },
        [yards, setHoveredEntities]
    );

    const handleMapClick = useCallback(
        event => {
            if (shiftPressedRef.current) {
                const startPoint = { lat: event.latLng.lat(), lng: event.latLng.lng() };
                const placeholderPoint1 = { lat: startPoint.lat + SMALL_LAT_LNG_INCREMENT, lng: startPoint.lng };
                const placeholderPoint2 = {
                    lat: placeholderPoint1.lat,
                    lng: placeholderPoint1.lng + SMALL_LAT_LNG_INCREMENT,
                };
                const placeholderPoint3 = { lat: startPoint.lat, lng: startPoint.lng + SMALL_LAT_LNG_INCREMENT };
                polygonPathRef.current = [startPoint, placeholderPoint1, placeholderPoint2, placeholderPoint3];

                if (polygonRef.current) {
                    polygonRef.current.setMap(null);
                }

                polygonRef.current = new window.google.maps.Polygon({
                    paths: [startPoint, placeholderPoint1, placeholderPoint2, placeholderPoint3],
                    ...POLYGON_PARAMS,
                });
                polygonRef.current.setMap(mapRef.current);
            } else {
                if (polygonRef.current) {
                    polygonRef.current.setMap(null);
                }
                polygonPathRef.current = [];
            }
        },
        [mapRef]
    );

    const handleMouseMove = useCallback(
        event => {
            const newPoint = { lat: event.latLng.lat(), lng: event.latLng.lng() };
            if (polygonPathRef.current.length !== 4) {
                return;
            }
            const newPolygonPath = [
                polygonPathRef.current[0],
                { lat: polygonPathRef.current[0].lat, lng: newPoint.lng },
                newPoint,
                { lat: newPoint.lat, lng: polygonPathRef.current[0].lng },
            ];
            polygonPathRef.current = newPolygonPath;
            polygonRef.current.setPath(newPolygonPath);
            updateHoveredYards(newPolygonPath);
        },
        [updateHoveredYards]
    );

    const selectYardsInPolygon = useCallback(() => {
        if (polygonRef.current) {
            const polygon = new window.google.maps.Polygon({ paths: polygonPathRef.current });
            const selected = yards.filter(yard =>
                window.google.maps.geometry.poly.containsLocation(
                    new window.google.maps.LatLng(yard.lat, yard.lng),
                    polygon
                )
            );
            const selectedIds = [...new Set(selected.map(yard => yard.id))];
            setSelectedIds(prevIds => uniq([...prevIds, ...selectedIds]));
        }
    }, [setSelectedIds, yards]);

    const handleKeyDown = useCallback(
        event => {
            if (event.key === SHIFT) {
                shiftPressedRef.current = true;
                mapRef?.current?.addListener('click', handleMapClick);
                mapRef?.current?.addListener('mousemove', handleMouseMove);
            }
        },
        [handleMapClick, handleMouseMove, mapRef]
    );

    const handleKeyUp = useCallback(
        event => {
            if (event.key === SHIFT) {
                shiftPressedRef.current = false;
                if (polygonPathRef.current.length) {
                    selectYardsInPolygon();
                }
                polygonPathRef.current = [];
                if (polygonRef.current) {
                    polygonRef.current.setMap(null);
                }
            }
            window?.google?.maps?.event?.clearListeners?.(mapRef.current, 'click');
            window?.google?.maps?.event?.clearListeners?.(mapRef.current, 'mousemove');
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [selectYardsInPolygon]
    );

    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);
        window.addEventListener('keyup', handleKeyUp);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
            window.removeEventListener('keyup', handleKeyUp);
        };
    }, [handleKeyDown, handleKeyUp]);

    useEffect(() => () => polygonRef?.current?.setMap(null), []);
};

export default usePolygonSelection;
