import React from 'react';
import PropTypes from 'prop-types';
import ReusableMap from 'components/reusables/Map/components/ReusableMap';
import { getWorkspaceRanches } from 'components/views/Workspace/selectors';
import { useSelector } from 'react-redux';
import { arrayOfObjectsShallowEqual } from '@beewise/react-utils/build/lib/comparison';
import useMap from 'components/reusables/Map/hooks/useMap';
import LeftPanel from '../LeftPanel';
import RightPanelMap from '../RightPanelMap';
import RightPanel from '../RightPanel';
import BhomeView from '../BhomeView';
import useResize from '../../hooks/useResize';

const BhomeViewWrapper = ({ isPublicView = false, hideMap = false, code }) => {
    const isSmallScreen = useResize();
    const ranches = useSelector(getWorkspaceRanches, arrayOfObjectsShallowEqual);
    const { mapRef, handleMapChange, mapOptions, handleMapLoad, handleOnPlacesChange } = useMap({ ranches });

    const mapComponent = isSmallScreen ? (
        <RightPanelMap />
    ) : (
        <ReusableMap
            isBhomeMap
            mapRef={mapRef}
            handleMapChange={handleMapChange}
            mapOptions={mapOptions}
            handleMapLoad={handleMapLoad}
            handleOnPlacesChange={handleOnPlacesChange}
        />
    );

    return (
        <main className="main main-bhome">
            {!isPublicView && <LeftPanel />}
            <BhomeView isPublicView={isPublicView} />
            {!hideMap && mapComponent}
            <RightPanel isPublicView={isPublicView} code={code} />
        </main>
    );
};

BhomeViewWrapper.propTypes = {
    isPublicView: PropTypes.bool,
    hideMap: PropTypes.bool,
    code: PropTypes.string,
};

export default BhomeViewWrapper;
