import React, {
  useEffect,
  useRef,
  useCallback,
  useImperativeHandle,
  forwardRef,
  memo
} from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import ReactDOM from 'react-dom';
import { noop } from 'lodash-es';
import Button from '@beewise/button';
import './Modal.scss';

const stopPropagation = (e) => e.stopPropagation();

const Modal = forwardRef((props, ref) => {
  const {
    isOpen,
    onOpen,
    onClose,
    onSave,
    children,
    className,
    header,
    appendTo = '#app',
    suppressClose,
    suppressCoverClose,
    contentClassName,
    footerComponent,
    isPurple,
    size
  } = props;

  const containerRef = useRef(document.createElement('div'));
  const parentRef = useRef(null);

  const handleKeyDown = useCallback(
    (e) => {
      if (isOpen && e.key === 'Enter' && typeof onSave === 'function') {
        e.preventDefault();
        onSave();
      }
    },
    [isOpen, onSave]
  );

  useEffect(() => {
    const parentElement =
      appendTo && document.querySelector(appendTo)
        ? document.querySelector(appendTo)
        : document.body;
    parentRef.current = parentElement;
    parentElement.appendChild(containerRef.current);
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      parentElement.removeChild(containerRef.current);
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [appendTo, handleKeyDown]);

  useImperativeHandle(
    ref,
    () => ({
      container: containerRef.current
    }),
    []
  );

  useEffect(() => {
    if (isOpen) {
      (onOpen || noop)();
    }
  }, [isOpen, onOpen]);

  if (!isOpen) {
    return null;
  }

  return ReactDOM.createPortal(
    <div
      className={cx('modal-wrapper', {
        [className]: !!className
      })}
      onClick={suppressClose || suppressCoverClose ? noop : onClose}
      role="presentation"
    >
      <div
        className={cx('modal-content', {
          'modal-content-with-header': !!header,
          [contentClassName]: contentClassName,
          'modal-content-purple': isPurple,
          [`modal-${size}`]: !!size
        })}
        onClick={stopPropagation}
        role="presentation"
      >
        {header && (
          <header className="modal-header">
            <h2 className="modal-header__text">{header}</h2>
            {suppressClose ? null : (
              <Button
                iconType="x"
                iconClassName="modal-close-icon"
                onClick={onClose}
                className="btn modal-close"
              />
            )}
          </header>
        )}
        <div className="modal-body">{children}</div>
        {footerComponent && <div className="modal-footer">{footerComponent}</div>}
      </div>
    </div>,
    containerRef.current
  );
});

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  className: PropTypes.string,
  header: PropTypes.string.isRequired,
  appendTo: PropTypes.string,
  children: PropTypes.node,
  suppressClose: PropTypes.bool,
  suppressCoverClose: PropTypes.bool,
  contentClassName: PropTypes.string,
  footerComponent: PropTypes.node,
  isPurple: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'medium', 'large', 'extra-large'])
};

export default memo(Modal);
