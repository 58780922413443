import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

const UpdatedHive = forwardRef(({ color = '#616161', ...rest }, ref) => (
  <svg
    ref={ref}
    width="14"
    height="12"
    viewBox="0 0 14 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.34375 0.600234C0.34375 0.401412 0.504927 0.240234 0.70375 0.240234H13.3037C13.5026 0.240234 13.6637 0.401412 13.6637 0.600234V2.40023C13.6637 2.59906 13.5026 2.76023 13.3037 2.76023H0.70375C0.504927 2.76023 0.34375 2.59906 0.34375 2.40023V0.600234ZM1.06375 0.960234V2.04023H12.9437V0.960234H1.06375Z"
      fill={color}
    />
    <path
      d="M9.57627 5.77682C9.62109 5.77682 9.66592 5.7676 9.70195 5.74958C9.74678 5.73156 9.78281 5.70475 9.81885 5.66872C9.88213 5.60588 9.91816 5.52458 9.91816 5.43493C9.91816 5.34484 9.88213 5.26398 9.81885 5.19191C9.68438 5.06579 9.45938 5.07458 9.33281 5.19191C9.27041 5.26398 9.23438 5.34484 9.23438 5.43493C9.23438 5.52458 9.27041 5.60588 9.33281 5.66872C9.40488 5.74079 9.48574 5.77682 9.57627 5.77682Z"
      fill={color}
    />
    <path
      d="M8.55811 6.6486C8.59414 6.66661 8.63984 6.67584 8.68467 6.67584C8.77432 6.67584 8.85518 6.63981 8.91846 6.56774C8.98174 6.5049 9.01777 6.4236 9.01777 6.33395C9.01777 6.24386 8.98174 6.15377 8.91846 6.09093C8.79277 5.9736 8.56777 5.9736 8.44121 6.09093C8.37881 6.15377 8.35156 6.24386 8.35156 6.33395C8.35156 6.4236 8.37881 6.51368 8.44121 6.56774C8.47725 6.60377 8.51328 6.63058 8.55811 6.6486Z"
      fill={color}
    />
    <path
      d="M10.2391 7.46813C10.3023 7.5402 10.392 7.57623 10.4825 7.57623C10.5722 7.57623 10.653 7.5402 10.7163 7.46813C10.7523 7.44132 10.7796 7.40529 10.7972 7.36002C10.806 7.32399 10.8156 7.27916 10.8156 7.23434C10.8156 7.14425 10.7796 7.05416 10.7163 6.99132C10.5906 6.87399 10.3656 6.87399 10.2391 6.99132C10.1767 7.05416 10.1406 7.14425 10.1406 7.23434C10.1406 7.27916 10.1494 7.32399 10.167 7.36002C10.1854 7.40529 10.2127 7.44132 10.2391 7.46813Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.96219 10.1404V2.40039H1.24219V10.5004C1.24219 10.6992 1.40337 10.8604 1.60219 10.8604H12.4022C12.601 10.8604 12.7622 10.6992 12.7622 10.5004V2.40039H12.0422V10.1404H1.96219Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.0553 10.1406C11.2541 10.1406 11.4153 10.3018 11.4153 10.5006V11.4006C11.4153 11.5994 11.2541 11.7606 11.0553 11.7606C10.8565 11.7606 10.6953 11.5994 10.6953 11.4006V10.5006C10.6953 10.3018 10.8565 10.1406 11.0553 10.1406Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.95375 10.1406C3.15257 10.1406 3.31375 10.3018 3.31375 10.5006V11.4006C3.31375 11.5994 3.15257 11.7606 2.95375 11.7606C2.75493 11.7606 2.59375 11.5994 2.59375 11.4006V10.5006C2.59375 10.3018 2.75493 10.1406 2.95375 10.1406Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.29688 8.25062C5.29688 8.0518 5.45805 7.89062 5.65687 7.89062H8.35687C8.5557 7.89062 8.71688 8.0518 8.71688 8.25062C8.71688 8.44945 8.5557 8.61062 8.35687 8.61062H5.65687C5.45805 8.61062 5.29688 8.44945 5.29688 8.25062Z"
      fill={color}
    />
  </svg>
));

UpdatedHive.propTypes = {
  color: PropTypes.string
};

UpdatedHive.displayName = 'UpdatedHive';

export default UpdatedHive;
