import {
    CHANGE_ENTITY_FILTER,
    CLEAR_ENTITY_FILTER,
    FETCH_WORKSPACE_DATA,
    FETCH_CREATE_YARD_ACTION,
    FETCH_UPDATE_YARD_ACTION,
    FETCH_GET_YARD_ACTION,
    CHANGE_YARD_SORT,
    SET_ENTITY_TO_INSPECT,
    FETCH_GET_YARD_WORKSPACE_EVENTS_BY_BHOME_IDS,
    FETCH_GET_RANCH_WORKSPACE_EVENTS_BY_BHOME_IDS,
    CLEAR_WORKSPACE_EVENTS,
    FETCH_WORKSPACE_RANCHES,
} from '../actionTypes';

export const fetchWorkspaceData = () => ({
    type: 'FETCH',
    params: {
        type: FETCH_WORKSPACE_DATA.default,
        url: '/bhomes/workspace',
    },
});

export const changeEntityFilter = filter => ({
    type: CHANGE_ENTITY_FILTER,
    filter,
});

export const clearEntityFilter = () => ({
    type: CLEAR_ENTITY_FILTER,
});

export const changeYardSort = sort => ({
    type: CHANGE_YARD_SORT,
    sort,
});

export const fetchCreateYardAction = ({ body, resolver, toastText }) => ({
    type: 'FETCH',
    params: {
        type: FETCH_CREATE_YARD_ACTION.default,
        url: '/bhomes/workspace/actions',
        body,
        method: 'POST',
        resolver,
        toastText,
    },
});

export const fetchUpdateYardAction = ({ body, resolver }) => ({
    type: 'FETCH',
    params: {
        type: FETCH_UPDATE_YARD_ACTION.default,
        url: '/bhomes/workspace/actions',
        body,
        method: 'PUT',
        resolver,
    },
});

export const fetchGetYardAction = () => ({
    type: 'FETCH',
    params: {
        type: FETCH_GET_YARD_ACTION.default,
        url: '/bhomes/workspace/actions',
    },
});

export const setEntityToInspect = entity => ({
    type: SET_ENTITY_TO_INSPECT,
    entity,
});

export const fetchGetYardWorkspaceEventsByBhomeIds = ({ yardId, days }) => ({
    type: 'FETCH',
    params: {
        type: FETCH_GET_YARD_WORKSPACE_EVENTS_BY_BHOME_IDS.default,
        url: `/yards/${yardId}/events/${days}`,
    },
});

export const fetchGetRanchWorkspaceEventsByBhomeIds = ({ ranchId, days }) => ({
    type: 'FETCH',
    params: {
        type: FETCH_GET_RANCH_WORKSPACE_EVENTS_BY_BHOME_IDS.default,
        url: `/ranches/v2/${ranchId}/events/${days}`,
    },
});

export const clearWorkspaceEvents = () => ({
    type: CLEAR_WORKSPACE_EVENTS,
});

export const fetchWorkspaceRanches = () => ({
    type: 'FETCH',
    params: {
        type: FETCH_WORKSPACE_RANCHES.default,
        url: '/ranches/v2/base',
    },
});
