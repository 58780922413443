import React, { useState, forwardRef, memo } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons';
import { faCircle } from '@fortawesome/pro-light-svg-icons';
import Tooltip from '@beewise/tooltip';
import { setEntityToInspect } from 'components/views/Workspace/actions';
import PieChart from 'components/reusables/PieChart';
import { BORDER_PADDING, DEFAULT_PADDING, TOOLTIP_SIZE } from '../constants';
import { formatEntityNameToMultiLine, ZOOM_LEVELS } from '../utils';
import './EntityMarker.scss';

export const EntityMarkerIcon = forwardRef(
    ({ entity, onClick, extraData, className, handleMouseEnter, handleMouseLeave }, ref) => {
        const handleClick = event => {
            event.stopPropagation();
            onClick?.(entity);
        };

        return (
            <div
                className="entity-marker-wrapper"
                role="presentation"
                onClick={handleClick}
                ref={ref}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                {entity.hiveCategories ? (
                    <Tooltip content={entity?.name} position="top center" className="custom-workspace-tooltip">
                        <div className={cx('entity-label', className)} role="presentation">
                            {extraData}
                            {!!entity?.totalHives && (
                                <PieChart hiveCategories={entity?.hiveCategories} width={28} height={28} />
                            )}
                            {entity?.totalHives}
                        </div>
                    </Tooltip>
                ) : (
                    <div className={cx('entity-label', className)} role="presentation">
                        {extraData}
                        {entity.name}
                    </div>
                )}
            </div>
        );
    }
);

EntityMarkerIcon.propTypes = {
    entity: PropTypes.shape({
        name: PropTypes.string.isRequired,
        bhomes: PropTypes.arrayOf(PropTypes.number),
        id: PropTypes.string.isRequired,
        hiveCategories: PropTypes.arrayOf(PropTypes.shape()),
        totalHives: PropTypes.number.isRequired,
    }).isRequired,
    onClick: PropTypes.func,
    className: PropTypes.string,
    extraData: PropTypes.node,
    handleMouseEnter: PropTypes.func,
    handleMouseLeave: PropTypes.func,
};

const EntityMarker = ({
    entity,
    onClick,
    isSelected,
    handleCheckboxClick,
    isHovered,
    clickedEntity,
    mapContainerRef,
    setHoveredEntity,
    currentZoom,
    isInspected,
}) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const [tooltipPosition, setTooltipPosition] = useState('top center');
    const isBhomePage = location.pathname.includes('beehome') || location.pathname.includes('bhome');

    const handleOnCheckboxClick = event => {
        event.stopPropagation();
        handleCheckboxClick(event, entity);
    };

    const handleMouseEnter = () => {
        setHoveredEntity && setHoveredEntity(entity.id);
        dispatch(setEntityToInspect(entity));
    };

    const handleMouseLeave = () => {
        setHoveredEntity && setHoveredEntity(null);
        dispatch(setEntityToInspect(null));
    };

    const markerRef = node => {
        if (!node) {
            return;
        }
        const markerRect = node.getBoundingClientRect();
        const mapRect = mapContainerRef.current?.getBoundingClientRect();
        if (!markerRect || !mapRect) {
            return;
        }
        const spaceAboveMarker = markerRect.top - mapRect.top;
        const spaceBelowMarker = mapRect.bottom - markerRect.bottom;
        const spaceLeftOfMarker = markerRect.left - mapRect.left;
        const spaceRightOfMarker = mapRect.right - markerRect.right;
        let newPosition;

        if (spaceAboveMarker >= TOOLTIP_SIZE.height + DEFAULT_PADDING) {
            newPosition = 'top center';
        } else if (spaceBelowMarker >= TOOLTIP_SIZE.height + DEFAULT_PADDING) {
            newPosition = 'bottom center';
        } else if (spaceRightOfMarker >= TOOLTIP_SIZE.height + BORDER_PADDING) {
            newPosition = 'right';
        } else if (spaceLeftOfMarker >= TOOLTIP_SIZE.height + BORDER_PADDING) {
            newPosition = 'left';
        } else {
            newPosition = 'top center';
        }

        if (newPosition !== tooltipPosition) {
            setTooltipPosition(newPosition);
        }
    };

    return (entity?.isRanch && currentZoom > ZOOM_LEVELS.LOCATION_MIN) ||
        (!entity?.isRanch && currentZoom > ZOOM_LEVELS.BHOME_MIN) ? (
        <div key={entity.id} className="entity-name">
            {formatEntityNameToMultiLine(entity.name)}
        </div>
    ) : (
        <EntityMarkerIcon
            ref={markerRef}
            entity={entity}
            className={cx({
                selected: isSelected,
                'clicked-entity': clickedEntity === entity.id,
                hovered: isHovered,
                inspected: isInspected,
            })}
            onClick={onClick}
            handleMouseEnter={handleMouseEnter}
            handleMouseLeave={handleMouseLeave}
            extraData={
                !isBhomePage && (
                    <div
                        className={cx('entity-label-checkbox', {
                            selected: isSelected,
                        })}
                        role="presentation"
                        onClick={handleOnCheckboxClick}
                    >
                        {!isSelected && (
                            <FontAwesomeIcon icon={faCircle} className="entity-label-checkbox-unselected" />
                        )}
                        <FontAwesomeIcon
                            icon={faCircleCheck}
                            className={cx('entity-label-checkbox-selected', {
                                hidden: !isSelected,
                                selected: isSelected,
                            })}
                        />
                    </div>
                )
            }
        />
    );
};

EntityMarker.propTypes = {
    entity: PropTypes.shape({
        name: PropTypes.string.isRequired,
        data: PropTypes.shape(),
        id: PropTypes.string.isRequired,
        hiveCategories: PropTypes.arrayOf(PropTypes.shape()),
        isRanch: PropTypes.bool,
    }).isRequired,
    onClick: PropTypes.func,
    isSelected: PropTypes.bool,
    handleCheckboxClick: PropTypes.func.isRequired,
    isHovered: PropTypes.bool,
    clickedEntity: PropTypes.string,
    mapContainerRef: PropTypes.shape(),
    setHoveredEntity: PropTypes.func,
    currentZoom: PropTypes.number,
    isInspected: PropTypes.bool,
};

export default memo(EntityMarker);
