import React from 'react';
import PropTypes from 'prop-types';
import Modal from '@beewise/modal';
import Button from '@beewise/button';
import constants from 'appConstants';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { omit } from 'lodash-es';
import { useForm, useFieldArray } from 'react-hook-form';
import { fetchCreateYard, fetchDeleteYard, fetchUpdateYard } from 'components/views/BeeHome/actions';
import { fetchCreateCompanyLocation } from 'components/views/Dashboard/actions';
import { getYardById } from 'components/views/Dashboard/selectors';
import { fetchWorkspaceData } from 'components/views/Workspace/actions';
import { DEFAULT_HISTORY, defaultValues, yardTypeOptions } from './utils';
import YardModalFields from './components/YardModalFields';

import './YardModal.scss';

const YardModal = ({ createCoords, onClose, yardId }) => {
    const editYard = useSelector(state => getYardById(state, yardId), shallowEqual);
    const dispatch = useDispatch();

    const isEditMode = !!yardId;

    const { control, handleSubmit, setValue, watch } = useForm({
        defaultValues: {
            ...defaultValues,
            ...(editYard ?? {}),
            history: isEditMode ? (editYard?.history?.sort((a, b) => b.year - a.year) ?? []) : [DEFAULT_HISTORY],
        },
    });

    const { fields, append, remove } = useFieldArray({ control, name: 'history', keyName: 'key' });

    const typeValue = watch('type');

    const isPollinationDrop = typeValue === yardTypeOptions[0]?.value;

    const createDrop = data =>
        dispatch(
            fetchCreateCompanyLocation(
                {
                    lat: createCoords.lat,
                    lng: createCoords.lng,
                    name: data.name,
                    locationType: constants.LOCATION_TYPES.IDLE,
                },
                onClose
            )
        );

    const createYard = data => {
        const filteredHistory = data.history.filter(item => item?.year);
        dispatch(
            fetchCreateYard(
                {
                    ...data,
                    capacity: data.capacity ? Number(data.capacity) : null,
                    history: filteredHistory?.length ? filteredHistory : null,
                    lat: createCoords.lat,
                    lng: createCoords.lng,
                },
                () => {
                    dispatch(fetchWorkspaceData());
                    onClose();
                }
            )
        );
    };

    const handleCreateSubmit = data => (isPollinationDrop ? createDrop(data) : createYard(data));

    const handleEditSubmit = data => {
        const { id, ...rest } = data;
        const filteredHistory = rest.history.filter(item => item?.year);

        dispatch(
            fetchUpdateYard(
                id,
                { ...omit(rest, ['createdAt', 'updatedAt', 'bhomes', 'companyId']), history: filteredHistory },
                onClose
            )
        );
    };

    const onSubmit = data => (isEditMode ? handleEditSubmit : handleCreateSubmit)(data);

    const handleDelete = () => {
        dispatch(fetchDeleteYard(editYard?.id, onClose));
    };

    const handleAddAnotherYear = () => append(DEFAULT_HISTORY);

    return (
        <Modal
            isOpen
            appendTo="#app"
            header="Place Yard"
            className="yard-modal"
            onClose={onClose}
            suppressCoverClose
            footerComponent={
                <>
                    {isEditMode && (
                        <Button className="secondary-blue delete-button" onClick={handleDelete}>
                            Delete
                        </Button>
                    )}
                    <Button className="secondary-blue" onClick={onClose}>
                        Cancel
                    </Button>
                    <Button className="primary-blue" onClick={handleSubmit(onSubmit)}>
                        Done
                    </Button>
                </>
            }
        >
            <YardModalFields
                fields={fields}
                isEditMode={isEditMode}
                control={control}
                handleAddAnotherYear={handleAddAnotherYear}
                isPollinationDrop={isPollinationDrop}
                watch={watch}
                setValue={setValue}
                remove={remove}
            />
        </Modal>
    );
};

YardModal.propTypes = {
    createCoords: PropTypes.shape(),
    yardId: PropTypes.string,
    onClose: PropTypes.func,
};

export default YardModal;
