import { createSelector } from 'reselect';
import constants from 'appConstants';
import { groupBy, keyBy, max, min } from 'lodash-es';
import { mapData, sortYards, HIVE_CATEGORY_COLORS, DEFAULT_HIVE_CONFIGURATION } from '../utils';
import { getBhomes, getLocations, getYards } from '../../Dashboard/selectors';
import { filterEntities } from '../utils/filters';

export const getWorkspaceData = state => state.workspace.data;
export const getIsWorkspaceFetched = state => state.workspace.isFetched;
export const getWorkspaceSearch = state => state.workspace.search;
export const getWorkspaceFilter = state => state.workspace.filter;
export const getWorkspaceSort = state => state.workspace.sort;
export const getWorkspaceActions = state => state.workspace.workspaceActions;
export const getInspectedEntity = state => state.workspace.inspectedEntity;
export const getYardEvents = state => state.workspace.yardEvents;
export const getRanchEvents = state => state.workspace.ranchEvents;
export const getWorkspaceRanches = state => state.workspace.ranches;

export const getAllEvents = createSelector([getYardEvents, getRanchEvents], (yardEvents, ranchEvents) => [
    ...(yardEvents || []),
    ...(ranchEvents || []),
]);

const getLatestAndPreviousDates = groupedByDate => {
    const dates = Object.keys(groupedByDate);
    return {
        latestDate: max(dates),
        previousPeriodDate: min(dates),
    };
};

export const getPreparedWorkspaceData = data => {
    const groupedByDate = groupBy(data, 'createdAt');
    const { latestDate, previousPeriodDate } = getLatestAndPreviousDates(groupedByDate);
    const todaysYardGroupedData = groupBy(groupedByDate[latestDate], 'yardId');
    const todaysRanchesGroupedData = groupBy(groupedByDate[latestDate], 'ranchId');
    const previousPeriodYardGroupedData = groupBy(groupedByDate[previousPeriodDate], 'yardId');
    const previousPeriodRanchesGroupedData = groupBy(groupedByDate[previousPeriodDate], 'ranchId');

    const yardsData = Object.keys(todaysYardGroupedData).reduce(
        mapData(todaysYardGroupedData, previousPeriodYardGroupedData),
        []
    );

    const ranchesData = Object.keys(todaysRanchesGroupedData).reduce(
        mapData(todaysRanchesGroupedData, previousPeriodRanchesGroupedData, true),
        []
    );

    return [...yardsData, ...ranchesData];
};

export const getHiveCategories = (bhomesList, recentDataByBhome) => {
    // strong, medium, weak, noData
    const categories = {
        green: 0,
        yellow: 0,
        red: 0,
        grey: 0,
    };

    bhomesList.forEach(bhome => {
        const bhomeData = recentDataByBhome[bhome?.id || bhome];
        if (!bhomeData?.markedHives?.length) {
            categories.grey += DEFAULT_HIVE_CONFIGURATION;
            return;
        }
        bhomeData.markedHives.forEach(({ beeFrameEvaluation }) => {
            const category = HIVE_CATEGORY_COLORS[beeFrameEvaluation] || 'grey';
            categories[category] += 1;
        });
    });
    const totalHiveCategoriesValue = Object.values(categories).reduce((total, value) => total + value, 0);

    return {
        categories: Object.entries(categories).map(([name, value]) => ({ name, value })),
        totalHiveCategoriesValue,
    };
};

const getGroupedWorkspaceData = (workspaceData, yards, ranches, bhomes, locations, workspaceActions, sort, filter) => {
    const bhomesByIds = keyBy(bhomes, 'id');
    const actionsUnifiedByEntityId = groupBy(
        workspaceActions.map(it => ({ ...it, entityId: it.ranch_id || it.yard_id })),
        'entityId'
    );

    const aggregatedData = getPreparedWorkspaceData(workspaceData);
    const aggregatedDataMap = new Map(aggregatedData.map(item => [item.id, item]));

    const processItem = item => {
        const aggregatedItem = aggregatedDataMap.get(item.id);
        const actionsFiltered = actionsUnifiedByEntityId[item.id] ?? [];
        const recentDataByBhome = keyBy(aggregatedItem?.data, 'id');

        const nonOperationalBhomesAmount = item.bhomes.reduce((acc, entityBhome) => {
            const bhomesData = bhomesByIds[entityBhome.id];
            return bhomesData?.operational_state === constants.BHOME_OPERATIONAL_STATES.NON_OPERATIONAL ? acc + 1 : acc;
        }, 0);

        const { categories, totalHiveCategoriesValue } = getHiveCategories(item.bhomes, recentDataByBhome);

        return {
            id: item.id,
            name: item.name,
            lat: item.lat,
            lng: item.lng,
            bhomes: item.bhomes,
            // TODO unify name for all entities
            isRanch: item.isRanch,
            ...aggregatedItem,
            workspaceActions: actionsFiltered,
            // keep value in the end to avoid overriding the regionName
            regionName: item.region ?? item?.regionName ?? item.googleRegion,
            nonOperationalBhomesAmount,
            totalHives: totalHiveCategoriesValue,
            hiveCategories: categories,
            ...(item.isRanch
                ? {
                      locations: locations
                          .filter(location => location.ranch_id === item.id)
                          .map(location => {
                              const { categories, totalHiveCategoriesValue } = getHiveCategories(
                                  location.bhome_ids,
                                  recentDataByBhome
                              );
                              return {
                                  ...location,
                                  hiveCategories: categories,
                                  totalHiveCategoriesValue,
                              };
                          }),
                  }
                : {}),
        };
    };

    const allItems = [
        ...yards.map(yard => ({ ...yard, isRanch: false })),
        ...ranches.map(ranch => ({ ...ranch, isRanch: true })),
    ];

    const combinedData = allItems.map(processItem);

    return (filter ? combinedData.filter(filterEntities(filter)) : combinedData).toSorted((a, b) =>
        sortYards(a, b, sort)
    );
};

export const getEntityGroupedData = createSelector(
    [
        getWorkspaceData,
        getYards,
        getWorkspaceRanches,
        getBhomes,
        getLocations,
        getWorkspaceActions,
        getWorkspaceSort,
        getWorkspaceFilter,
    ],
    (data, yards, ranches, bhomes, locations, workspaceActions, sort, filter) =>
        getGroupedWorkspaceData(data, yards, ranches, bhomes, locations, workspaceActions, sort, filter)
);

export const getDefaultEntityGroupedData = createSelector(
    [getWorkspaceData, getYards, getWorkspaceRanches, getBhomes, getLocations, getWorkspaceActions, getWorkspaceSort],
    (data, yards, ranches, bhomes, locations, workspaceActions, sort) =>
        getGroupedWorkspaceData(data, yards, ranches, bhomes, locations, workspaceActions, sort, null)
);
