import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/reusables/Icon';
import Badge, { BADGE_TYPE, SIZE } from 'components/reusables/Badge';

const FrameAction = ({ data: { isAllHives, hives, messages }, handleCancelHiveAction, isCancelable }) => (
    <>
        <Icon icon="hive-workspace" />
        {isAllHives ? (
            'All hives'
        ) : (
            <div className="hive-badge-container">
                {hives.map((hive, index) => (
                    <Badge
                        type={BADGE_TYPE.DEFAULT}
                        size={SIZE.SMALL}
                        key={`${hive}-${messages[index]}-${index}`}
                        onCancel={isCancelable && handleCancelHiveAction(index)}
                    >
                        {hive}
                    </Badge>
                ))}
            </div>
        )}
    </>
);

FrameAction.propTypes = {
    data: PropTypes.shape({
        isAllHives: PropTypes.bool,
        hives: PropTypes.arrayOf(PropTypes.string),
        messages: PropTypes.arrayOf(PropTypes.string),
    }),
    handleCancelHiveAction: PropTypes.func,
    isCancelable: PropTypes.bool,
};

export default FrameAction;
