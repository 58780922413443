import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { useTransition } from 'react-transition-state';
import MarkerTooltip from '../MarkerTooltip';

const MarkerWrapper = forwardRef(({ map, marker, children, onClick }, ref) => {
    const [{ status, isMounted }, toggle] = useTransition({
        mountOnEnter: true,
        unmountOnExit: true,
        preEnter: true,
        timeout: 500,
    });

    const handleHideTooltip = () => toggle(false);

    const bhomeIds = marker?.bhomes ? marker?.bhomes?.map(bhome => bhome?.id) : [];

    return (
        <div
            role="presentation"
            className="marker-wrapper"
            onMouseEnter={() => toggle(true)}
            onMouseLeave={handleHideTooltip}
            onClick={onClick}
        >
            <div>
                {children}
                {isMounted && (
                    <MarkerTooltip
                        ref={ref}
                        map={map}
                        status={status}
                        id={marker?.id}
                        name={marker.name}
                        bhomeIds={marker.bhome_ids ?? bhomeIds}
                        handleHideTooltip={handleHideTooltip}
                    />
                )}
            </div>
        </div>
    );
});

MarkerWrapper.propTypes = {
    children: PropTypes.node,
    map: PropTypes.shape(),
    marker: PropTypes.shape(),
    name: PropTypes.string,
    onClick: PropTypes.func,
};

export default MarkerWrapper;
