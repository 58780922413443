import React from 'react';
import PropTypes from 'prop-types';
import Button from '@beewise/button';
import { SelectFormField, InputFormField, VALIDATIONS, PhoneInputFormField } from '@beewise/hook-form';
import { yardTypeOptions } from '../utils';
import HistoryItem from './HistoryItem';

const YardModalFields = ({
    isEditMode,
    control,
    watch,
    remove,
    isPollinationDrop,
    fields,
    handleAddAnotherYear,
    setValue,
}) => (
    <>
        <div className="yard-header">Yard general information:</div>
        <div className="yard-section">
            <div className="yard-column">
                <SelectFormField
                    disabled={isEditMode}
                    name="type"
                    control={control}
                    label="Type"
                    options={yardTypeOptions}
                    required
                />
                {!isPollinationDrop && (
                    <>
                        <InputFormField name="name" control={control} label="Yard name" required />
                        <InputFormField
                            name="capacity"
                            control={control}
                            label="Hive Capacity"
                            required
                            pattern={VALIDATIONS.NUMBER}
                        />
                    </>
                )}
            </div>
            <div className="yard-column">
                {isPollinationDrop ? (
                    <InputFormField name="name" control={control} label="Drop name" />
                ) : (
                    <InputFormField name="notes" control={control} label="Notes" multiline className="yard-textarea" />
                )}
            </div>
        </div>
        {!isPollinationDrop && (
            <>
                <div className="yard-header">Yard owner contact info:</div>
                <div className="yard-section">
                    <div className="yard-column">
                        <InputFormField name="ownerName" control={control} label="Name" />
                    </div>
                    <div className="yard-column">
                        <PhoneInputFormField name="ownerPhone" control={control} label="Phone" />
                    </div>
                </div>
            </>
        )}
        {!isPollinationDrop &&
            fields.map((field, i) => (
                <HistoryItem
                    key={`yard-year-${i}`}
                    control={control}
                    isEditMode={isEditMode}
                    historyIndex={i}
                    watch={watch}
                    setValue={setValue}
                    remove={remove}
                />
            ))}
        {!isPollinationDrop && (
            <Button className="secondary-blue add-another-year" onClick={handleAddAnotherYear}>
                + Add Another Year
            </Button>
        )}
    </>
);

YardModalFields.propTypes = {
    isEditMode: PropTypes.bool,
    control: PropTypes.shape(),
    watch: PropTypes.func,
    remove: PropTypes.func,
    isPollinationDrop: PropTypes.bool,
    fields: PropTypes.arrayOf(PropTypes.shape()),
    handleAddAnotherYear: PropTypes.func,
    setValue: PropTypes.func,
};

export default YardModalFields;
