import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@beewise/tooltip';
import PieChart from 'components/reusables/PieChart';
import MarkerWrapper from '../MarkerWrapper';
import './LocationMarker.scss';

const LocationMarker = props => {
    const markerRef = useRef();
    return (
        <MarkerWrapper {...props} ref={markerRef}>
            <div className="entity-marker-wrapper" role="presentation" ref={markerRef}>
                <Tooltip
                    content={`Drop ${props.marker?.name}`}
                    position="top center"
                    className="custom-workspace-tooltip"
                >
                    <div className="drop-label" role="presentation">
                        {props.marker?.hiveCategories && !!props.marker.totalHiveCategoriesValue && (
                            <PieChart hiveCategories={props.marker?.hiveCategories} width={28} height={28} />
                        )}
                        {props.marker.totalHiveCategoriesValue}
                    </div>
                </Tooltip>
            </div>
        </MarkerWrapper>
    );
};

LocationMarker.propTypes = {
    marker: PropTypes.shape(),
    map: PropTypes.shape(),
    maps: PropTypes.shape(),
};

export default LocationMarker;
