export const defaultValues = {
    type: null,
    name: '',
    capacity: '',
    notes: '',
    ownerName: '',
    ownerPhone: undefined,
    history: [],
};

export const yardTypeOptions = [
    {
        label: 'Pollination Drop',
        value: 'pollination',
    },
    {
        label: 'Honey Flow Yard',
        value: 'honey',
    },
    {
        label: 'Overwintering Yard',
        value: 'overwintering',
    },
    {
        label: 'Loading Yard',
        value: 'loading',
    },
    {
        label: 'Splits/Multipurpose Yard',
        value: 'multipurpose',
    },
];

export const yearsOptions = () =>
    new Array(30).fill(undefined).map((item, i) => ({
        label: `${new Date().getFullYear() - i}`,
        value: new Date().getFullYear() - i,
    }));

export const DEFAULT_HISTORY = {
    year: null,
    yield: '',
    weak: '',
    medium: '',
    strong: '',
    loss: '',
    total: '',
    rate: '',
};
