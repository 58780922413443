const getElementSum = (frame, element, isHistory = false, useStrictKeyName = false) =>
  Object.keys(frame).reduce((acc, frameKey) => {
    const lowerCasedKey = frameKey.toLowerCase();
    if (
      lowerCasedKey.includes('side') &&
      frame[frameKey] &&
      ((!isHistory && !lowerCasedKey.includes('previous')) ||
        (isHistory && lowerCasedKey.includes('previous')))
    ) {
      const sideElement = Object.keys(frame[frameKey]).reduce((sideAcc, sideKey) => {
        if (useStrictKeyName && sideKey === element) {
          return sideAcc + frame[frameKey][sideKey];
        }
        if (!useStrictKeyName && sideKey.includes(element)) {
          return sideAcc + frame[frameKey][sideKey];
        }
        return sideAcc;
      }, 0);
      return acc + sideElement;
    }
    return acc;
  }, 0);

const isBroodFrame = ({ frame, threshold, isHistory = false }) => {
  const larvaSum = getElementSum(frame, 'larva', isHistory) || 0;
  const cellsSum = getElementSum(frame, 'cell', isHistory, true) || 1;
  return (larvaSum / cellsSum) * 100 >= threshold;
};

const isHoneyFrame = ({ frame, threshold, isHistory = false }) => {
  const honeySum = getElementSum(frame, 'honey', isHistory) || 0;
  const cellsSum = getElementSum(frame, 'cell', isHistory, true) || 1;
  return (honeySum / cellsSum) * 100 >= threshold;
};

const isBeeFrame = ({ frame, threshold, isHistory = false }) => {
  let beeSum = 0;
  if (isHistory) {
    beeSum =
      ((frame?.previousSideA?.beeCount?.beesPercent ||
        frame?.previous_side_a?.beeCount?.beesPercent ||
        0) +
        (frame?.previousSideB?.beeCount?.beesPercent ||
          frame?.previous_side_b?.beeCount?.beesPercent ||
          0)) /
      2;
  } else {
    beeSum =
      ((frame?.sideA?.beeCount?.beesPercent || frame?.side_a?.beeCount?.beesPercent || 0) +
        (frame?.sideB?.beeCount?.beesPercent || frame?.side_b?.beeCount?.beesPercent || 0)) /
      2;
  }
  return beeSum >= threshold;
};

const isOpenBroodFrame = ({ frame, threshold, isHistory = false }) => {
  const larva2to3Sum = getElementSum(frame, 'larva_2_to_3', isHistory) || 0;
  const larva4to5Sum = getElementSum(frame, 'larva_4_to_5', isHistory) || 0;
  const larva6to7Sum = getElementSum(frame, 'larva_6_to_7', isHistory) || 0;
  const openBroodSum = larva2to3Sum + larva4to5Sum + larva6to7Sum;
  const cellsSum = getElementSum(frame, 'cell', isHistory, true) || 1;

  return (openBroodSum / cellsSum) * 100 >= threshold;
};

export default {
  getElementSum,
  isBroodFrame,
  isHoneyFrame,
  isBeeFrame,
  isOpenBroodFrame
};
