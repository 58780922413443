import { createAsyncAction } from '@beewise/utils';

export const FETCH_WORKSPACE_DATA = createAsyncAction('FETCH_WORKSPACE_DATA');
export const FETCH_CREATE_YARD_ACTION = createAsyncAction('FETCH_CREATE_YARD_ACTION');
export const FETCH_UPDATE_YARD_ACTION = createAsyncAction('FETCH_UPDATE_YARD_ACTION');
export const FETCH_GET_YARD_ACTION = createAsyncAction('FETCH_GET_YARD_ACTION');
export const FETCH_GET_YARD_WORKSPACE_EVENTS_BY_BHOME_IDS = createAsyncAction(
    'FETCH_GET_YARD_WORKSPACE_EVENTS_BY_BHOME_IDS'
);
export const FETCH_GET_RANCH_WORKSPACE_EVENTS_BY_BHOME_IDS = createAsyncAction(
    'FETCH_GET_RANCH_WORKSPACE_EVENTS_BY_BHOME_IDS'
);
export const FETCH_WORKSPACE_RANCHES = createAsyncAction('FETCH_WORKSPACE_RANCHES');
export const CHANGE_ENTITY_FILTER = 'CHANGE_ENTITY_FILTER';
export const CLEAR_ENTITY_FILTER = 'CLEAR_ENTITY_FILTER';
export const CHANGE_YARD_SORT = 'CHANGE_YARD_SORT';
export const SET_ENTITY_TO_INSPECT = 'SET_ENTITY_TO_INSPECT';
export const CLEAR_WORKSPACE_EVENTS = 'CLEAR_WORKSPACE_EVENTS';
