import React from 'react';
import { faSearch, faXmark } from '@fortawesome/pro-regular-svg-icons';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import TextField from '../BaseTextField';
import { SIZES, TYPES } from '../utils';
import props from '../props';
import './SearchField.scss';

const IconRight = ({ value, iconRight, onClick }) => {
  if (!value) return null;
  return (
    iconRight || (
      <button type="button" onClick={onClick}>
        <FontAwesomeIcon icon={faXmark} />
      </button>
    )
  );
};

const SearchField = ({ size = SIZES.XSMALL, iconRight, iconLeft, className, ...props }) => (
  <TextField
    {...props}
    type={TYPES.TEXT}
    className={cn('search-field', className)}
    iconLeft={iconLeft || <FontAwesomeIcon icon={faSearch} />}
    size={size}
    iconRight={
      <IconRight value={props.value} iconRight={iconRight} onClick={() => props.onChange('')} />
    }
  />
);

SearchField.propTypes = props;
IconRight.propTypes = {
  value: PropTypes.string,
  iconRight: PropTypes.node,
  onClick: PropTypes.func
};

export default SearchField;
