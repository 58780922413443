import React from 'react';
import cn from 'classnames';
import { components } from 'react-select';
import PropTypes from 'prop-types';
import Checkbox from '@beewise/checkbox-field';

const CheckboxOption = ({
  isCheckboxEnabled,
  selectOption,
  data,
  isSelected,
  innerRef,
  innerProps,
  ...props
}) => {
  const handleChange = (event) => {
    const isChecked = event.target.checked;

    if (isChecked) {
      selectOption(data);
    } else {
      selectOption({ ...data, isSelected: false });
    }
  };

  return (
    <components.Option {...props}>
      {isCheckboxEnabled ? (
        <Checkbox label={data.label} checked={isSelected} onChange={handleChange} />
      ) : (
        <div
          ref={innerRef}
          {...innerProps}
          className={cn({
            'custom-option--active': isSelected
          })}
        >
          {data.label}
        </div>
      )}
    </components.Option>
  );
};

CheckboxOption.propTypes = {
  data: PropTypes.shape(),
  selectOption: PropTypes.func,
  isCheckboxEnabled: PropTypes.bool,
  isSelected: PropTypes.bool,
  innerRef: PropTypes.shape(),
  innerProps: PropTypes.shape()
};

export default CheckboxOption;
