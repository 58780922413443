import React from 'react';
import EntityMarker from 'components/reusables/Map/components/EntityMarker';
import BhomeMarker from 'components/reusables/Map/components/BhomeMarker';
import LocationMarker from 'components/reusables/Map/components/LocationMarker';
import { keyBy } from 'lodash-es';
import { POLYGON_PARAMS } from 'components/reusables/Map/components/constants';
import GroupMarker from 'components/reusables/Map/components/GroupMarker';
import { ZOOM_LEVELS } from 'components/reusables/Map/components/utils';

const getIsGroupMarker = (item, map) => item.properties?.cluster || map?.getZoom() < ZOOM_LEVELS.BHOME_MIN;

const getCoordinates = item => {
    const [lng, lat] = item.geometry.coordinates;

    return { lat, lng };
};

const getId = item => item.id || item.marker.id;

export const drawMarkers = (clusters, supercluster, handleClusterMarkerClick, map, maps) =>
    clusters?.map(item => {
        const isNotAssignedBhome = !item?.marker?.locationId && !item?.marker?.yard_id;
        const key = item.id || JSON.stringify(item?.marker?.bhome_ids);
        return getIsGroupMarker(item, map) && isNotAssignedBhome ? (
            <GroupMarker
                type="blured-marker"
                key={key}
                map={map}
                maps={maps}
                cluster={item}
                supercluster={supercluster}
                onClusterClick={handleClusterMarkerClick}
                {...getCoordinates(item)}
            />
        ) : (
            <BhomeMarker key={key} map={map} maps={maps} location={item.marker} {...getCoordinates(item)} />
        );
    });

export const drawLocationMarkers = (clusters, map, onLocationClick) =>
    clusters?.map(item => (
        <LocationMarker
            key={getId(item)}
            marker={item?.marker}
            map={map}
            onClick={() => onLocationClick(item?.marker)}
            {...getCoordinates(item)}
        />
    ));

const isPolygonInBounds = (polygon, bounds) => {
    const path = polygon.getPath();
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < path.getLength(); i++) {
        const point = path.getAt(i);
        if (bounds?.contains(point)) {
            return true; // At least one vertex is within bounds
        }
    }
    return false; // No vertices are within bounds
};

export const drawPolygons = ({ mapRef, ranches, bounds }) => {
    const polygons = [];

    if (!mapRef.current || !ranches?.length) {
        return polygons;
    }

    const currentZoom = mapRef.current?.getZoom();

    ranches.forEach(item => {
        item.polygons?.forEach(poly => {
            const polygon = new window.google.maps.Polygon({
                paths: poly,
                ...POLYGON_PARAMS,
            });
            const isInsideBounds = isPolygonInBounds(polygon, bounds);

            if (!isInsideBounds || currentZoom <= ZOOM_LEVELS.LOCATION_MIN) {
                return;
            }
            polygons.push(polygon);
            polygon.setMap(mapRef.current);
        });
    });

    return polygons;
};

export const drawWorkspaceYardRanchClusters = ({
    clusters,
    handleEntityMapClick,
    selectedIds,
    handleCheckboxClick,
    hoveredEntities,
    clickedEntity,
    mapContainerRef,
    setHoveredEntity,
    hoveredEntity,
    entities,
    currentZoom,
}) => {
    const entitiesByIds = keyBy(entities, 'id');
    const processedIds = new Set();

    return clusters.reduce((acc, item) => {
        const markerId = item?.marker?.id;
        if (!entitiesByIds[markerId] || processedIds.has(markerId)) return acc;

        processedIds.add(markerId);

        const id = getId(item);
        const entity = entities.find(({ id }) => id === markerId) || item?.marker;
        const isSelected = selectedIds?.includes(markerId);
        const isInspected = clickedEntity === markerId;
        const isHovered = hoveredEntities?.includes(markerId);
        const isSingleHovered = hoveredEntity === markerId;

        acc.push(
            <EntityMarker
                key={id}
                entity={entity}
                isSelected={isSelected}
                onClick={handleEntityMapClick}
                handleCheckboxClick={handleCheckboxClick}
                isHovered={isHovered || isSingleHovered}
                isInspected={isInspected}
                selectedIds={selectedIds}
                clickedEntity={clickedEntity}
                mapContainerRef={mapContainerRef}
                setHoveredEntity={setHoveredEntity}
                currentZoom={currentZoom}
                {...getCoordinates(item)}
            />
        );

        return acc;
    }, []);
};
