import React, { memo, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { PieChart as Chart, Pie, Cell, Tooltip as RechartsTooltip, Sector } from 'recharts';
import Smooth from 'react-smooth';
import { COLORS } from 'components/reusables/Map/components/constants';
import { CATEGORY_DATA_CHART } from 'components/views/Workspace/utils';
import './PieChart.scss';

const CATEGORY_LABELS = Object.fromEntries(CATEGORY_DATA_CHART.map(({ color, label }) => [color, label]));

const CustomTooltip = ({ active, payload, mousePosition, chartContainerRef }) => {
    if (!active || !payload?.length) return;

    const { value, payload: entry } = payload[0];
    const chartRect = chartContainerRef.current.getBoundingClientRect();

    return (
        <div
            className="custom-workspace-tooltip"
            style={{
                position: 'absolute',
                left: `${mousePosition.x - chartRect.left + 10}px`,
                top: `${mousePosition.y - chartRect.top + 10}px`,
                pointerEvents: 'none',
                visibility: active ? 'visible' : 'hidden',
            }}
        >
            <p className="label">{`${CATEGORY_LABELS[entry.fill]}: ${value}`}</p>
        </div>
    );
};

CustomTooltip.propTypes = {
    active: PropTypes.bool,
    payload: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.number,
            payload: PropTypes.shape({
                fill: PropTypes.string,
            }),
        })
    ),
    mousePosition: PropTypes.shape(),
    chartContainerRef: PropTypes.shape(),
};

const renderActiveShape = props => {
    const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill } = props;
    const outerRadiusTo = outerRadius > 10 ? 2 : 0.5;
    return (
        <Smooth to={{ outerRadius: outerRadius + outerRadiusTo }} from={{ outerRadius }} key={fill} duration={300}>
            {interpolatedProps => (
                <Sector
                    cx={cx}
                    cy={cy}
                    innerRadius={innerRadius}
                    outerRadius={interpolatedProps.outerRadius}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    fill={fill}
                />
            )}
        </Smooth>
    );
};

const PieChart = ({ hiveCategories, totalHives, width, height }) => {
    const [isTooltipActive, setIsTooltipActive] = useState(false);
    const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

    const chartRef = useRef(null);
    const chartContainerRef = useRef(null);

    const handleMouseMove = event => {
        setMousePosition({ x: event.clientX, y: event.clientY });
    };

    const handleMouseLeave = () => {
        setIsTooltipActive(false);
    };

    const handleMouseEnter = () => {
        setIsTooltipActive(true);
    };

    // to prevent showing tooltip when cursor is out
    useEffect(() => {
        const handleMouseMove = event => {
            if (chartRef.current && !chartRef.current.container.contains(event.target)) {
                setIsTooltipActive(false);
            }
        };

        document.addEventListener('mousemove', handleMouseMove);

        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
        };
    }, []);

    if (!hiveCategories.length) return;

    const nonZeroCategories = hiveCategories.filter(category => category.value > 0);
    const isSingleCategory = nonZeroCategories.length === 1;

    return (
        <div ref={chartContainerRef} className="chart-container" onMouseMove={handleMouseMove} role="presentation">
            <Chart ref={chartRef} width={width} height={height}>
                <Pie
                    data={hiveCategories}
                    cx="50%"
                    cy="50%"
                    innerRadius={width > 50 ? 25 : 4}
                    outerRadius={width > 50 ? 35 : 10}
                    paddingAngle={0}
                    dataKey="value"
                    activeShape={isTooltipActive ? renderActiveShape : null}
                    startAngle={90}
                    isAnimationActive={false}
                    endAngle={450}
                    onMouseLeave={handleMouseLeave}
                    onMouseEnter={handleMouseEnter}
                >
                    {hiveCategories.map((entry, index) => (
                        <Cell
                            key={entry.name}
                            fill={COLORS[index % COLORS.length]}
                            strokeWidth={isSingleCategory ? 0 : 1}
                        />
                    ))}
                </Pie>
                {!!totalHives && (
                    <text x="50%" y="50%" dy={4} textAnchor="middle">
                        <tspan fontSize="14" fontWeight="600" fill="#111111" dy="-2">
                            {totalHives}
                        </tspan>
                        <tspan fontSize="11" fontWeight="400" fill="#828282" x="50%" dy="1.2em">
                            hives
                        </tspan>
                    </text>
                )}
                <RechartsTooltip
                    content={<CustomTooltip mousePosition={mousePosition} chartContainerRef={chartContainerRef} />}
                    active={isTooltipActive}
                />
            </Chart>
        </div>
    );
};

PieChart.propTypes = {
    hiveCategories: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            value: PropTypes.number,
        })
    ).isRequired,
    totalHives: PropTypes.number,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
};

export default memo(PieChart);
