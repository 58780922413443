import dayjs from 'dayjs';
import { useRef, useState, useEffect, useMemo } from 'react';
import Supercluster from 'supercluster';
import { isEqual, groupBy, keyBy } from 'lodash-es';
import { getHiveCategories, getPreparedWorkspaceData } from 'components/views/Workspace/selectors';
import { ZOOM_LEVELS } from 'components/reusables/Map/components/utils';

const EMPTY_ARRAY = [];

const getCluster = markers =>
    markers.map(marker => ({
        type: 'Feature',
        properties: { cluster: false, crimeId: marker.id },
        marker,
        geometry: {
            type: 'Point',
            coordinates: [parseFloat(marker.lng), parseFloat(marker.lat)],
        },
    }));

// created own hook based on use-clusters lib does to performance issue https://github.com/leighhalliday/use-supercluster/issues/77
const useSuperCluster = ({ points, bounds, zoom, options }) => {
    const superclusterRef = useRef();
    const pointsRef = useRef();
    const prevClusters = useRef(EMPTY_ARRAY);
    const [clusters, setClusters] = useState(EMPTY_ARRAY);
    const zoomInt = Math.round(zoom);

    useEffect(() => {
        const featurePoints = getCluster(points);

        if (
            !superclusterRef.current ||
            !isEqual(pointsRef.current, points) ||
            !isEqual(superclusterRef.current?.options, options)
        ) {
            superclusterRef.current = new Supercluster(options);
            superclusterRef.current.load(featurePoints);
        }

        if (bounds) {
            const newClusters = superclusterRef.current.getClusters(bounds, zoomInt);
            if (!isEqual(prevClusters.current, newClusters)) {
                setClusters(newClusters.length ? newClusters : EMPTY_ARRAY);
                prevClusters.current = newClusters;
            }
        }

        pointsRef.current = points;
    }, [points, bounds, zoomInt, options]);

    return { clusters, supercluster: superclusterRef.current };
};

const superClusterParams = {
    minZoom: 0,
    maxZoom: 0,
    radius: 0,
    minPoints: 0,
};

const superClusterBhomeParams = {
    minZoom: 2,
    maxZoom: 10,
    radius: 120,
    minPoints: 1,
};

const formatBhome = ({ bhome }) => ({
    bhome_ids: [bhome.id],
    lat: bhome?.gps?.latitude && Number(bhome?.gps?.latitude),
    lng: bhome?.gps?.longitude && Number(bhome?.gps?.longitude),
    gpsUpdateDate:
        bhome.gps?.timestamp &&
        typeof bhome.gps?.timestamp === 'number' &&
        dayjs.tz(bhome.gps.timestamp, bhome.gps.timezone).utc(),
    name: `Bhome ${bhome.id} GPS`,
    locationId: bhome.location_id,
    ranch_id: bhome.ranch_id,
    yard_id: bhome.yard_id,
});

const filterBhomes = (bhomes, filterFn) => bhomes.filter(filterFn).map(bhome => formatBhome({ bhome }));

const getGroupedBhomes = ({ bhomes, filterFn, locationsById }) => {
    const grouped = groupBy(bhomes.filter(filterFn), 'location_id');
    return Object.entries(grouped).map(([locationId, ranchBhomes]) => ({
        ...locationsById[locationId],
        bhome_ids: ranchBhomes.map(bhome => bhome.id),
    }));
};

export default ({ workspaceData, bhomes, locations, yards, ranches, mapOptions }) => {
    const locationsById = useMemo(() => keyBy(locations, 'id'), [locations]);
    const aggregatedData = getPreparedWorkspaceData(workspaceData);
    const aggregatedDataMap = new Map(aggregatedData.map(item => [item.id, item]));

    const yardBhomes = useMemo(() => filterBhomes(bhomes, bhome => bhome.yard_id && !bhome.location_id), [bhomes]);
    const notAssignedBhomes = useMemo(
        () => filterBhomes(bhomes, bhome => !bhome.location_id && !bhome.yard_id),
        [bhomes]
    );

    const assignedBhomes = useMemo(
        () => [
            ...getGroupedBhomes({ bhomes, filterFn: bhome => !bhome.ranch_id, locationsById }),
            ...getGroupedBhomes({ bhomes, filterFn: bhome => bhome.ranch_id, locationsById }),
        ],
        [bhomes, locationsById]
    );

    const totalBhomes = useMemo(
        () => [...yardBhomes, ...assignedBhomes, ...notAssignedBhomes],
        [yardBhomes, assignedBhomes, notAssignedBhomes]
    );

    const locationClusters = useSuperCluster({
        points:
            mapOptions?.zoom > ZOOM_LEVELS.LOCATION_MIN && mapOptions?.zoom < ZOOM_LEVELS.BHOME_MIN
                ? locations.map(location => {
                      const aggregatedItem = aggregatedDataMap.get(location.ranch_id);
                      const recentDataByBhome = keyBy(aggregatedItem?.data, 'id');
                      const { categories, totalHiveCategoriesValue } = getHiveCategories(
                          location.bhome_ids,
                          recentDataByBhome
                      );
                      return {
                          ...location,
                          hiveCategories: categories,
                          totalHiveCategoriesValue,
                      };
                  })
                : [],
        bounds: mapOptions?.boundsCoords,
        zoom: mapOptions?.zoom,
        options: superClusterParams,
    }).clusters;

    const { clusters: bhomeClusters, supercluster: bhomeSuperClusters } = useSuperCluster({
        points: mapOptions?.zoom >= ZOOM_LEVELS.BHOME_MIN ? totalBhomes : notAssignedBhomes,
        bounds: mapOptions?.boundsCoords,
        zoom: mapOptions?.zoom,
        options: superClusterBhomeParams,
    });

    const { clusters: ranchClusters, supercluster: ranchSuperClusters } = useSuperCluster({
        points: ranches,
        bounds: mapOptions?.boundsCoords,
        zoom: mapOptions?.zoom,
        options: superClusterParams,
    });

    const { clusters: yardClusters, supercluster: yardSuperClusters } = useSuperCluster({
        points: yards,
        bounds: mapOptions?.boundsCoords,
        zoom: mapOptions?.zoom,
        options: superClusterParams,
    });

    return {
        locationClusters,
        bhomeClusters,
        bhomeSuperClusters,
        ranchClusters,
        ranchSuperClusters,
        yardClusters,
        yardSuperClusters,
    };
};
