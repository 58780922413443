import { CATEGORY_DATA_CHART } from 'components/views/Workspace/utils';

export const TOOLTIP_SIZE = { width: 300, height: 240 };
export const DEFAULT_PADDING = 8;
export const BORDER_PADDING = 24;
export const CHART_WIDTH = 60;
export const COLORS = CATEGORY_DATA_CHART.map(item => item.color);

export const POLYGON_PARAMS = {
    capturing: true,
    strokeColor: '#fdba12',
    strokeWeight: 2,
    fillColor: 'transparent',
    fillOpacity: 0.7,
    editable: false,
    draggable: false,
    clickable: true,
    cursor: null,
};
