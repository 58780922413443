import React from 'react';
import LoadingZoneIcon from '../LoadingZoneIcon';

const isMarkerInBounds = (bounds, lat, lng) => {
    if (!bounds) {
        return false;
    }

    return bounds.contains({ lat, lng });
};

const renderAdditionalMapMarkers = ({ ranches, bounds }) =>
    // TODO: refactor there is not such prop as loading zone coordinates, it is inside pins
    ranches?.reduce((acc, { loadingZoneCoordinates }) => {
        if (loadingZoneCoordinates) {
            const coords = { lat: loadingZoneCoordinates.lat, lng: loadingZoneCoordinates.lng };

            if (isMarkerInBounds(bounds, coords.lat, coords.lng)) {
                acc.push(<LoadingZoneIcon {...coords} key={JSON.stringify(coords)} />);
            }
        }

        return [...acc];
    }, []);

export default renderAdditionalMapMarkers;
