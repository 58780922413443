import { createAsyncAction } from '@beewise/utils';

export const SET_CURRENT_BHOME = 'SET_CURRENT_BHOME';
export const ENABLE_ALL_HIVES = 'ENABLE_ALL_HIVES';
export const CLEAR_BHOMES_VIEW = 'CLEAR_BHOMES_VIEW';
export const CLEAR_OPERATION_ERRORS = 'CLEAR_OPERATION_ERRORS';
export const TOGGLE_FRAMES_HISTORY = 'TOGGLE_FRAMES_HISTORY';
export const TOGGLE_HIVE_MANAGMENT_MODAL = 'TOGGLE_HIVE_MANAGMENT_MODAL';
export const TOGGLE_BALANCE_HIVE_MODE = 'TOGGLE_BALANCE_HIVE_MODE';
export const SET_FRAME_TO_INSPECT = 'SET_FRAME_TO_INSPECT';
export const SET_FRAME_TO_INSPECT_BY_FRAME_ID = 'SET_FRAME_TO_INSPECT_BY_FRAME_ID';
export const SET_FRAME_TO_INSPECT_BY_RFID = 'SET_FRAME_TO_INSPECT_BY_RFID';
export const CLEAR_YARD_WIDGET_DATA = 'CLEAR_YARD_WIDGET_DATA';
export const SET_SELECTED_FRAMES_FOR_BALANCE = 'SET_SELECTED_FRAMES_FOR_BALANCE';
export const SET_LOCATION_FILTER = 'SET_LOCATION_FILTER';
export const SET_ITEM_FILTER = 'SET_ITEM_FILTER';
export const TOGGLE_SELECTED_HIVE = 'TOGGLE_SELECTED_HIVE';
export const SET_SNAPSHOT_TO_SHOW = 'SET_SNAPSHOT_TO_SHOW';
export const SET_HIVE_TO_INSPECT = 'SET_HIVE_TO_INSPECT';
export const CHANGE_FILTERS = 'CHANGE_FILTERS';
export const RESET_FILTERS = 'RESET_FILTERS';
export const SET_ZOOM_LEVEL = 'SET_ZOOM_LEVEL';

export const FETCH_HIVES = createAsyncAction('FETCH_HIVES');
export const FETCH_RANCHES = createAsyncAction('FETCH_RANCHES');
export const FETCH_BEEKEEPER_RANCHES = createAsyncAction('FETCH_BEEKEEPER_RANCHES');
export const FETCH_YARDS = createAsyncAction('FETCH_YARDS');
export const FETCH_LAYOUT_SNAPSHOT = createAsyncAction('FETCH_LAYOUT_SNAPSHOT');
export const FETCH_CREATE_YARD = createAsyncAction('FETCH_CREATE_YARD');
export const FETCH_UPDATE_YARD = createAsyncAction('FETCH_UPDATE_YARD');
export const FETCH_DELETE_YARD = createAsyncAction('FETCH_DELETE_YARD');
export const FETCH_FILL_FEEDERS = createAsyncAction('FETCH_FILL_FEEDERS');
export const FETCH_CLIMATE_INFO = createAsyncAction('FETCH_CLIMATE_INFO');
export const FETCH_SEND_COUNT_BEES = createAsyncAction('FETCH_SEND_COUNT_BEES');
export const FETCH_CURRENT_BHOME = createAsyncAction('FETCH_CURRENT_BHOME');
export const FETCH_SEND_SCAN_COMMAND = createAsyncAction('FETCH_SEND_SCAN_COMMAND');
export const FETCH_UPDATE_BHOME_LAST_VISITED = createAsyncAction('FETCH_UPDATE_BHOME_LAST_VISITED');
export const FETCH_FRAMES_LAYOUT = createAsyncAction('FETCH_FRAMES_LAYOUT');
export const FETCH_BHOME_NOTES = createAsyncAction('FETCH_BHOME_NOTES');
export const CREATE_BHOME_NOTE = createAsyncAction('CREATE_BHOME_NOTE');
export const UPDATE_BHOME_NOTE = createAsyncAction('UPDATE_BHOME_NOTE');
export const FETCH_INSPECTED_FRAME_IMAGES = createAsyncAction('FETCH_INSPECTED_FRAME_IMAGES');
export const FETCH_YARD_STATS_WIDGET_DATA = createAsyncAction('FETCH_YARD_STATS_WIDGET_DATA');
export const FETCH_BHOME_STATS_WIDGET_DATA = createAsyncAction('FETCH_BHOME_STATS_WIDGET_DATA');
export const FETCH_MODIFY_MODE = createAsyncAction('FETCH_MODIFY_MODE');
export const FETCH_MODIFY_BULK_MODE = createAsyncAction('FETCH_MODIFY_BULK_MODE');
export const FETCH_UPDATE_BHOME = createAsyncAction('FETCH_UPDATE_BHOME');
export const FETCH_PENDING_MESSAGES = createAsyncAction('FETCH_PENDING_MESSAGES');
export const FETCH_CANCEL_ACTIONS = createAsyncAction('FETCH_CANCEL_ACTIONS');
export const FETCH_DONE_MESSAGES = createAsyncAction('FETCH_DONE_MESSAGES');
