import React from 'react';
import PropTypes from 'prop-types';
import SmallHive from '../SmallHive';
import './Station.scss';

const Station = ({ hives, markedHives, selectedFrameOptions, beeCountFrameShift, thermalStationYRange }) => (
    <div className="station-v2">
        {hives?.map((hive, index) => (
            <SmallHive
                index={index}
                key={hive.id}
                hive={hive}
                name={hive.name}
                markedHives={markedHives}
                selectedFrameOptions={selectedFrameOptions}
                beeCountFrameShift={beeCountFrameShift}
                thermalStationYRange={thermalStationYRange}
            />
        ))}
    </div>
);

Station.propTypes = {
    hives: PropTypes.arrayOf(PropTypes.shape()),
    markedHives: PropTypes.arrayOf(PropTypes.shape()),
    selectedFrameOptions: PropTypes.shape(),
    beeCountFrameShift: PropTypes.number,
    thermalStationYRange: PropTypes.shape({
        current: PropTypes.shape({
            yMin: PropTypes.number,
            yMax: PropTypes.number,
        }),
        history: PropTypes.shape({
            yMin: PropTypes.number,
            yMax: PropTypes.number,
        }),
    }),
};
export default Station;
