import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMap } from '@fortawesome/pro-light-svg-icons';
import cx from 'classnames';
import AsidePanel from 'components/reusables/AsidePanel';
import { useToggle } from '@beewise/react-utils';
import ReusableMap from 'components/reusables/Map/components/ReusableMap';

import './RightPanelMap.scss';
import useMap from 'components/reusables/Map/hooks/useMap';
import { useSelector } from 'react-redux';
import { getWorkspaceRanches } from 'components/views/Workspace/selectors';
import { arrayOfObjectsShallowEqual } from '@beewise/react-utils/build/lib/comparison';

const RightPanelMap = () => {
    const [isPanelOpen, , setIsPanelOpen, setIsPanelClose] = useToggle();
    const ranches = useSelector(getWorkspaceRanches, arrayOfObjectsShallowEqual);
    const { mapRef, handleMapChange, mapOptions, handleMapLoad, handleOnPlacesChange } = useMap({ ranches });

    return (
        <div
            className={cx('right-panel-map-wrapper', { opened: isPanelOpen })}
            onMouseEnter={setIsPanelOpen}
            onMouseLeave={setIsPanelClose}
            role="presentation"
        >
            <FontAwesomeIcon className={cx('right-panel-map-icon', { opened: isPanelOpen })} icon={faMap} />
            <AsidePanel
                isPanelOpen={isPanelOpen}
                handleOpenPanel={setIsPanelOpen}
                onClose={setIsPanelClose}
                side="right"
            >
                <div className="right-panel-map">
                    <ReusableMap
                        isBhomeMap
                        mapRef={mapRef}
                        handleMapChange={handleMapChange}
                        mapOptions={mapOptions}
                        handleMapLoad={handleMapLoad}
                        handleOnPlacesChange={handleOnPlacesChange}
                    />
                </div>
            </AsidePanel>
        </div>
    );
};

export default RightPanelMap;
