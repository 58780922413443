import cn from 'classnames';
import Icon from '@beewise/icon';
import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

const MarkerIcon = forwardRef(({ className, icon, count, children, color, ...props }, ref) => (
    <div ref={ref} className={cn('marker', { [className]: !!className })} role="presentation" {...props}>
        <Icon type={icon} className="hex" color={color} />
        <span className="marker-value">{count}</span>
        {children}
    </div>
));

MarkerIcon.propTypes = {
    className: PropTypes.string,
    icon: PropTypes.string.isRequired,
    count: PropTypes.number,
    children: PropTypes.node,
    onClick: PropTypes.func,
    onAnimationEnd: PropTypes.func,
    color: PropTypes.string,
};

export default MarkerIcon;
